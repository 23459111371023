import React from 'react';
import {Link} from 'react-router-dom';
import imgs from './../../../assets/img/img';

import './style/Footer.css';

const Footer = (props) => {
  let year = new Date().getFullYear();
  return (
    <div id='footer'>
      <div className='b-container page side'>
        <div className='contact-section'>
          <Link className='web' to='/contact'>
            Əlaqə
          </Link>
          <a target='_blank' rel='noopener noreferrer' href='https://www.facebook.com/3sual.az'>
            <img alt='facebook page' src={imgs.facebook} />
          </a>
          <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/3sual.az'>
            <img alt='instagram account' src={imgs.instagram} />
          </a>
        </div>
        <div>
          <h2>Azərbaycan dilində intellektual oyunlar üçün sual bazası</h2>
          <p>Copyright © {year}. Bəhruz Aydınlı</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

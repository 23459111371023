export const LOADING_ON = "LOADING_ON";
export const LOADING_OFF = "LOADING_OFF";

export const SET_HOME_PACKAGES = "SET_HOME_PACKAGES";
export const GET_HOME_PACKAGES = "GET_HOME_PACKAGES";

export const SET_STATISTICS = "SET_STATISTICS";
export const GET_STATISTICS = "GET_STATISTICS";

export const SET_USER = "SET_USER";
export const SET_USER_ERROR = "SET_USER_ERROR";
export const SET_USER_LOGGED_IN = "SET_USER_LOGGED_IN";
export const GET_USER = "GET_USER";
export const LOG_OUT = "LOG_OUT";

export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_USER_PHOTO = "SET_USER_PHOTO";
export const SET_PROFILE = "SET_PROFILE";

import {
  LOADING_ON,
  LOADING_OFF,
  SET_HOME_PACKAGES,
  SET_STATISTICS,
  SET_USER,
  SET_USER_ERROR,
  SET_USER_LOGGED_IN,
  LOG_OUT,
  SET_USER_PHOTO,
  SET_PROFILE,
} from "./../types";
import sualtap from "../../const/axios";

export const loadingOn = () => ({ type: LOADING_ON });
export const loadingOff = () => ({ type: LOADING_OFF });

export const getHomePackages = () => async (dispatch) => {
  dispatch({
    type: LOADING_ON,
  });
  await sualtap.get("/packages/forhome").then((res) => {
    dispatch({
      type: SET_HOME_PACKAGES,
      payload: res.data.packages,
    });
  });
  dispatch({
    type: LOADING_OFF,
  });
};

export const setPackages = (data) => {
  return {
    type: SET_HOME_PACKAGES,
    payload: data,
  };
};

export const getStatistics = () => async (dispatch) => {
  dispatch({
    type: LOADING_ON,
  });
  await sualtap.get("/info/stats").then((res) => {
    dispatch({
      type: SET_STATISTICS,
      payload: res.data,
    });
  });
  dispatch({
    type: LOADING_OFF,
  });
};

export const setUserPhoto = (data) => {
  return {
    type: SET_USER_PHOTO,
    payload: data,
  };
};

export const logUserIn = (data) => async (dispatch) => {
  dispatch({
    type: LOADING_ON,
  });
  let newData = null;
  await sualtap
    .get(`users/current`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${data}`,
      },
    })
    .then((res) => {
      newData = res.data;
      localStorage.setItem("access_token", JSON.stringify(res.data.token));
      dispatch({
        type: SET_USER_LOGGED_IN,
        payload: newData,
      });
    })
    .catch((er) => {
      logOut();
    });
  dispatch({
    type: LOADING_OFF,
  });
};

export const loggedIn = (data) => {
  return {
    type: SET_USER,
    payload: { data },
  };
};

export const logInUser = (email, password) => async (dispatch) => {
  if (email.trim().length === 0 || password.trim().length === 0) {
    dispatch({
      type: SET_USER_ERROR,
      payload: { message: "Email və şifrə daxil edilməlidir" },
    });
  } else {
    var obj = {
      email,
      password,
      isAdmin: false,
    };
    await sualtap
      .post(`auth/login`, JSON.stringify(obj))
      .then((res) => {
        if (res.data.success) {
          localStorage.setItem(
            "access_token",
            JSON.stringify({ ...res.data.token, lastLogin: new Date() })
          );
          dispatch({
            type: SET_USER,
            payload: { data: res.data },
          });
        } else {
          return res.data.message;
        }
      })
      .catch((error) => {
        return error.message;
      });
  }
};

export const logOut = () => (dispatch) => {
  localStorage.removeItem("access_token");
  dispatch({
    type: LOG_OUT,
  });
};

export const setProfile = (payload) => {
  return {
    type: SET_PROFILE,
    payload,
  };
};

export const getProfile = (type, username) => async (dispatch) => {
  dispatch({
    type: LOADING_ON,
  });
  if (type) {
    await sualtap.get("users/profile").then((res) => {
      dispatch({
        type: SET_PROFILE,
        payload: { ...res.data, error: false },
      });
    });
  } else {
    await sualtap
      .get(`users/profile?username=${username}`)
      .then((res) => {
        dispatch({
          type: SET_PROFILE,
          payload: { ...res.data, error: false },
        });
      })
      .catch(() => {
        dispatch({
          type: SET_PROFILE,
          payload: { error: true },
        });
      });
  }
  dispatch({
    type: LOADING_OFF,
  });
};

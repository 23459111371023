import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import history from './../const/history'
import { loadingOff, loadingOn, logUserIn } from './../redux/actions/index'
import Loader from './Elements/Loader'
import NotFound from './Helpers/LineHeader/NotFound'
import Footer from './Layout/Footer/Footer'
import Header from './Layout/Header/Header'
import AuthorPage from './Pages/Authors/AuthorPage'
import AuthorThemes from './Pages/Authors/AuthorThemes'
import Authors from './Pages/Authors/Authors'
import Calculate from './Pages/Calc'
import Contact from './Pages/Contact/Contact'
import Order from './Pages/Contact/Order'
import Forgot from './Pages/Forgot/Forgot'
import Reset from './Pages/Forgot/Reset'
import Home from './Pages/Home/Home'
import { Confirm } from './Pages/Info/Confirm'
import { Info } from './Pages/Info/Info'
import Login from './Pages/Login/Login'
import Package from './Pages/Package/Package'
import Tour from './Pages/Package/Tour'
import EditorsPackages from './Pages/Packages/EditorsPackages'
import Packages from './Pages/Packages/Packages'
import PlayGame from './Pages/PlayGame/PlayGame'
import Svoyak from './Pages/PlayGame/Svoyak'
import Profile from './Pages/Profile/Profile'
import Register from './Pages/Register/Register'
import Search from './Pages/Search/Search'
import Tournament from './Pages/Tournaments/Tournament'
import Tournaments from './Pages/Tournaments/Tournaments'
import Soon from './Soon'
import { AllPackages } from './Pages/Package/All'
import { Marafon } from './Pages/Marafon/Marafon'

class App extends React.Component {
  state = {
    isSoon: false,
    visible: false,
  }

  componentDidMount = async () => {
    this.backListener = history.listen((location) => {
      if (location.action === 'POP') {
        history.goBack()
      }
    })
    if (localStorage.getItem('access_token')) {
      var data = JSON.parse(localStorage.getItem('access_token'))
      this.props.logUserIn(data)
    }
  }

  render() {
    return !this.state.isSoon ? (
      <>
        {this.props.loader > 0 ? <Loader /> : null}
        <Header />
        <Route exact path="/turnirler">
          <Tournaments />
        </Route>
        <Route
          exact
          path="/turnirler/:slug"
          render={({ match }) => <Tournament params={match.params} />}
        />
        <Route
          exact
          path="/authors/:page?"
          render={({ match }) => <Authors params={match.params} />}
        />
        <Route exact path="/search">
          <Search />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/register">
          {!this.props.user.isLoggedIn ? <Register /> : <NotFound />}
        </Route>
        <Route exact path="/packages">
          <Packages />
        </Route>
        <Route exact path="/calc">
          <Calculate />
        </Route>
        <Route exact path="/svoyak">
          <Svoyak />
        </Route>
        <Route exact path="/oyna-admin">
          <PlayGame isAdmin />
        </Route>
        <Route exact path="/oyna">
          <PlayGame isAdmin={false} />
        </Route>
        <Route exact path="/all">
          <AllPackages />
        </Route>
        <Route exact path="/forgot">
          {!this.props.user.isLoggedIn ? <Forgot /> : <NotFound />}
        </Route>
        <Route
          exact
          path="/profile/:username?"
          render={({ match }) => <Profile params={match.params} />}
        />
        <Route
          exact
          path="/password/:code?/:email?"
          render={({ match }) => <Reset params={match.params} />}
        />
        <Route exact path="/login">
          <Login />
        </Route>
        <Route
          exact
          path="/success"
          render={({ match }) => <Info params={match.params} />}
        />
        <Route
          exact
          path="/confirm/:code?/:email?"
          render={({ match }) => <Confirm params={match.params} />}
        />
        <Route
          exact
          path="/package/:id"
          render={({ match }) => <Package params={match.params} />}
        />
        <Route
          exact
          path="/editor/packages/:id"
          render={({ match }) => <EditorsPackages params={match.params} />}
        />
        <Route
          exact
          path="/author/questions/:id/:page?"
          render={({ match }) => <AuthorPage params={match.params} />}
        />
        <Route
          exact
          path="/author/themes/:id/:page?"
          render={({ match }) => <AuthorThemes params={match.params} />}
        />
        <Route
          exact
          path="/packages/tournament/:id"
          render={({ match }) => <Tour params={match.params} />}
        />
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/order">
          <Order />
        </Route>
        <Route path="/marafon">
          <Marafon />
        </Route>
        <Route exact path="/sual"></Route>
        <Footer />
      </>
    ) : (
      <>
        <Route path="/">
          <Soon />
        </Route>
        <Route
          exact
          path="/package/:id"
          render={({ match }) => <Package params={match.params} />}
        />
        <Route exact path="/register">
          <Register />
        </Route>
      </>
    )
  }
}

const mapStateToProps = ({ loader, user }) => {
  return {
    loader,
    user,
  }
}

export default connect(mapStateToProps, { loadingOn, loadingOff, logUserIn })(
  App
)

import React from "react";
import Question from "./Single/Question";

class Questions extends React.Component {
  state = {
    opens: []
  };

  allOpen() {
    let resp = true;
    if (!this.props.phase) {
      return false;
    }
    this.props.phase.questions.forEach(q => {
      if (!this.state.opens.includes(q.values[0].id)) {
        resp = false;
      }
    });
    return resp;
  }
  toggleAll = () => {
    const show = this.allOpen();
    if (!show) {
      let arr = [...this.state.opens];
      this.props.phase.questions.forEach(q => {
        arr.push(q.values[0].id)
      });
      this.setState({
        opens: arr
      });
    } else {
      this.setState({
        opens: []
      });
    }
  };

  componentDidMount() {
    this.renderQuestions();
  }
  componentDidUpdate() {
    this.renderQuestions();
  }

  toggleOpen = question => {
    let ope = [...this.state.opens];
    if (this.state.opens.includes(question)) {
      this.setState({
        opens: ope.filter(o => o !== question)
      });
    } else {
      ope.push(question);
      this.setState({
        opens: ope
      });
    }
  };

  renderQuestions() {
    if (this.props.phase === undefined) {
      return null;
    }
    return this.props.phase.questions.map((qu, index) => (
      <Question
        key={index}
        opens={this.state.opens}
        question={qu}
        index={index}
        toggleOpen={this.toggleOpen}
      />
    ));
  }

  render() {
    return (
      <>
        <div
          className="toggleButton"
          onClick={() => this.toggleAll()}
        >
          {this.allOpen()
            ? "Bütün cavabları gizlət"
            : "Bütün cavabları göstər"}
        </div>
        <div>{this.renderQuestions()}</div>
      </>
    );
  }
}

export default Questions;

import { Tabs } from 'antd'
import React, { useState } from 'react'
import { Table } from './Table'
const { TabPane } = Tabs

const rounds = new Array(20).fill(0)
const teams = [
  'Berserk',
  'Vendomat NX',
  'JagerMeister',
  'Rast',
  'Santa Klara',
  'Avanqard',
  'Prestij',
  'Summertime Stonkness',
  'Nirvana',
  'Stalker',
  'Kaspi',
  'Hərum',
  'Dory',
  'Queentett',
  'Re:volver',
  'Mindhunters',
  'IC-300',
  'Invicta',
  'Sükut',
  'Interpreters United',
]
export const Marafon = () => {
  const [tms, setTms] = useState(
    teams.map((t) => ({ title: t, qs: new Array(15).fill('') }))
  )

  const handleChange = (tIndex, qIndex, val) => {
    setTms((prev) => {
      const res = [...prev]
      res[tIndex].qs[qIndex] = val
      return res
    })
  }

  return (
    <div className="b-container">
      <Tabs defaultActiveKey="1">
        {rounds.map((round, index) => {
          return (
            <TabPane tab={index + 1} key={index + 1}>
              <Table
                tabIndex={index}
                onChange={handleChange}
                teams={tms}
                round={index + 1}
              />
            </TabPane>
          )
        })}
      </Tabs>
    </div>
  )
}

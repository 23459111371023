import React from "react";
import { Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import sualtap from "./../../../const/axios";
import "./style/style.css";

function beforeUpload(file) {
  const isJpgOrPng =
    (file.type === "image/jpeg" || file.type === "image/png") &&
    file.name.split(".")[file.name.split(".").length - 1] !== "svg";
  if (!isJpgOrPng) {
    message.error(
      "Yalnızca png və jpg formatında şəkillər yükləyə bilərsiniz."
    );
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Faylın ölçüsü 2 meqabaytdan kiçik olmalıdır.");
  }
  return isJpgOrPng && isLt2M;
}

const Demo = (props) => {
  const customIt = async (e) => {
    console.log(e.file);
    let form_data = new FormData();
    const filename = Math.random(1, 999999) + Date.now() + e.file.name;
    form_data.append("file", e.file, filename);
    await sualtap
      .post("users/photo", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        props.imageUpload(res.data);
      });
  };

  return (
    <ImgCrop rotate>
      <Upload
        style={{ width: "150px", height: "150px" }}
        customRequest={(e) => customIt(e)}
        listType="picture-card"
        beforeUpload={beforeUpload}
      >
        + Yüklə
      </Upload>
    </ImgCrop>
  );
};

export default Demo;

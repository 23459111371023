import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import sualtap from './../../../const/axios'
import { loadingOff, loadingOn } from './../../../redux/actions'
import { Phase } from './Phase'
import Questions from './Questions'
import Quizzes from './Quizzes'
import Themes from './Themes'
import './style/style.css'

class Pack extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      faulty: false,
    }
  }

  componentDidMount = async () => {
    this.props.loadingOn()
    await sualtap
      .get(`/packages/foruse?id=${this.props.package_id}`)
      .then((res) => {
        this.setState({
          faulty: !Boolean(res.data),
        })
        if (!res.data) {
          return
        }
        const phs = []
        res.data.package.phases.forEach((phase) => {
          if (!phase.subs?.length) {
            phs.push(phase)
          } else {
            phase.subs.forEach((sub) => {
              phs.push({
                ...sub,
                name: `${phase.name} > ${sub.name}`,
              })
            })
          }
        })
        res.data.package.phases = phs
        this.setState({
          data: { ...res.data },
        })
      })
    this.props.loadingOff()
  }

  renderQuestions() {
    return this.state.data.package.phases.map((phase) => {
      return <Phase key={phase.id} phase={phase} />
    })
  }

  renderQuestions = (phase) => {
    switch (this.state.data.package.game.id) {
      case 1:
        return <Questions phase={phase} />
      case 2:
        return <Themes type={true} phase={phase} />
      case 3:
        return <Themes type={false} phase={phase} />
      case 4:
        return <Questions phase={phase} />
      case 5:
        return <Themes type={true} erudit={true} phase={phase} />
      case 6:
        return <Questions phase={phase} />
      case 7:
        return <Quizzes type={true} erudit={true} phase={phase} />
      default:
        return <div>Salam</div>
    }
  }

  renderAuthors() {
    let arr = []
    if (!this.state.data.package) {
      return null
    }
    this.state.data.package.phases.forEach((p) => {
      if (!p.subs) {
        if (this.state.data.package.game.with_Theme) {
          p.themes.forEach((q) => {
            q.authors.forEach((a) => {
              if (!arr.includes(a.fullname)) {
                arr.push(a.fullname)
              }
            })
          })
        } else {
          p.questions.forEach((q) => {
            q.authors.forEach((a) => {
              if (!arr.includes(a.fullname)) {
                arr.push(a.fullname)
              }
            })
          })
        }
      } else {
        p.subs.forEach((ph) => {
          if (this.state.data.package.game.with_Theme) {
            ph.themes.forEach((q) => {
              q.authors.forEach((a) => {
                if (!arr.includes(a.fullname)) {
                  arr.push(a.fullname)
                }
              })
            })
          } else {
            ph.questions.forEach((q) => {
              q.authors.forEach((a) => {
                if (!arr.includes(a.fullname)) {
                  arr.push(a.fullname)
                }
              })
            })
          }
        })
      }
    })
    return arr.length > 0 ? (
      <h4>
        <b>Müəlliflər: </b>
        <span>{arr.join(', ')}</span>
      </h4>
    ) : null
  }

  render() {
    const { data } = this.state
    let name = ''
    name +=
      data.tournament &&
      data.tournament.isLeague &&
      data.tournament.continuation
        ? data.tournament.continuation + ' / '
        : ''
    name += data.tournament ? data.tournament.name + ' ' : ''
    name += data.package && data.package.name ? ' / ' + data.package.name : ''
    const game = data.package ? data.package.game.name : ''

    return !this.state.faulty ? (
      <div className="b-container page">
        <Helmet>
          <title>
            {name && name} - {game && game}
          </title>
        </Helmet>{' '}
        <div className="top-layer bg-white flex jcsb">
          <h2 className="bold">{name}</h2>
        </div>
        <div className="center-layer">
          <div
            className="right-layer bg-white position-relative"
            style={{ width: '100%' }}
          >
            {data.tournament &&
            (data.tournament.organizations.length ||
              data.tournament.organizers.length) ? (
              <h3>
                <b>
                  {data.tournament.organizers.length +
                    data.tournament.organizations.length >
                  1
                    ? 'Təşkilatçılar: '
                    : 'Təşkilatçı: '}{' '}
                </b>{' '}
                {data.tournament.organizations.map((e) => e.name).join(', ')}
                {data.tournament.organizations.length !== 0 &&
                data.tournament.organizers.length > 0
                  ? ', '
                  : null}{' '}
                {data.tournament.organizers.map((e) => e.fullname).join(', ')}
              </h3>
            ) : null}
            {data.package && data.package.editors.length ? (
              <h3>
                <b>
                  {data.package.editors.length > 1
                    ? 'Redaktorlar: '
                    : 'Redaktor: '}
                </b>{' '}
                {data.package.editors.map((e) => e.fullname).join(', ')}
              </h3>
            ) : null}
            {this.renderAuthors()}
            {data.package ? (
              <>
                <h4>
                  <b>Oynanıldığı tarix: </b>
                  {data.package.played.substring(0, 10)}
                </h4>
                <h4>
                  <b>Əlavə edildiyi tarix: </b>
                  {data.package.added.substring(0, 10)}
                </h4>
                <h4>{data.package.information}</h4>
                {data.package.phases.map((phase) => (
                  <div style={{ marginTop: '20px' }}>
                    <h3>{phase.name}</h3>
                    {this.renderQuestions(phase)}
                  </div>
                ))}
              </>
            ) : null}
          </div>
        </div>
      </div>
    ) : null
  }
}

export default connect(() => {}, { loadingOn, loadingOff })(Pack)

import axios from "axios";
import siteUrl from "./const";

export const sualtap = axios.create({
  baseURL: `${siteUrl}api`,
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${
      localStorage.getItem("access_token")
        ? JSON.parse(localStorage.getItem("access_token"))
        : ""
    }`,
  },
});

export default sualtap;

import vahid_liga from './15.png'
import header from './header.png'
import click from './click-1263.svg'
import facebook from './facebook.svg'
import instagram from './instagram.svg'
import memorial from './memorial.jpeg'
import comedy from './comedy.png'
import fortinalia from './fortinalia.png'

export default {
  vahid_liga,
  header,
  click,
  facebook,
  instagram,
  memorial,
  comedy,
  fortinalia,
}

import React, { useEffect } from "react";
import { Result, Button } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export const Info = () => {
  useEffect(() => {
  }, []);

  return (
    <div
      className="page bg-white"
      style={{ width: "auto", margin: "0 auto", padding: "0 50px" }}
    >
      <Result
        icon={<SmileOutlined />}
        title="Təbriklər! Hesabınızı uğurla aktivləşdirdiniz"
        extra={
          <Link to="/">
            <Button type="primary">Əsas səhifəyə qayıt</Button>
          </Link>
        }
      />
    </div>
  );
};

import React from "react";
import sualtap from "../../../const/axios";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import Package from "../../Elements/Package/Package";
import { connect } from "react-redux";
import { loadingOff, loadingOn, setPackages } from "../../../redux/actions";
import { Helmet } from "react-helmet";

class Tour extends React.Component {
  state = {
    continuation: null,
    topText: "",
    single: false,
    tournaments: [],
    packages: []
  };

  componentDidMount = async () => {
    this.props.loadingOn();
    await sualtap
      .get(`packages/forcont?id=${this.props.params.id}`)
      .then(res => {
        if (res.data.continuation) {
          let packs = [];
          res.data.tournaments.forEach(element => {
            element.packages.forEach(el => {
              packs.push(el);
            });
          });
          if (res.data.continuation.isLeague) {
            this.setState({
              topText: res.data.continuation.name,
              single: false,
              tournaments: res.data.tournaments
            });
          } else {
            this.setState({
              topText: res.data.continuation.name,
              single: true,
              packages: packs
            });
          }
          this.props.setPackages(packs);
        } else {
          const tournament = res.data.tournaments[0];
          this.setState({
            topText: tournament.name,
            single: true,
            packages: tournament.packages
          });
          this.props.setPackages(tournament.packages);
        }
        this.props.loadingOff();
      });
  };

  render() {
    return (
      <div className="b-container page">
        <Helmet>
          <title>{this.state.topText}</title>
        </Helmet>
        <div className="top-layer bg-white">
          <h2 className="bold">{this.state.topText}</h2>
        </div>
        <div className="layer">
          {this.state.single ? (
            <Row gutter={16}>
              {this.state.packages.map((item, index) => {
                return (
                  <Col key={index} lg={8} sm={12} xs={24}>
                    <Link to={`/package/${item.id}`}>
                      <Package pack={item} free={true} />
                    </Link>
                  </Col>
                );
              })}
            </Row>
          ) : (
            this.state.tournaments.map((t, index) => {
              return (
                <div key={index}>
                  {t.packages.length ? (
                    <h3 className="bold t-name">{t.name}</h3>
                  ) : null}
                  <div>
                    <Row gutter={16}>
                      {t.packages.map((item, ind) => {
                        return (
                          <Col key={ind} lg={8} sm={12} xs={24}>
                            <Link to={`/package/${item.id}`}>
                              <Package pack={item} free={true} />
                            </Link>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              );
            })
          )}
          <div className="left-layer"></div>
          <div className="right-layer"></div>
        </div>
      </div>
    );
  }
}

export default connect(null, { loadingOn, loadingOff, setPackages })(Tour);

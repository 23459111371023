import React from "react";
import {
  Switch,
  Button,
  Checkbox,
  Input,
  DatePicker,
  Row,
  Col,
  InputNumber,
  message
} from "antd";
import moment from "moment";
import sualtap from "./../../../const/axios";
import "./style/style.css";
import { connect } from "react-redux";
import { loadingOn, loadingOff } from "./../../../redux/actions";

const { RangePicker } = DatePicker;

const CheckboxGroup = Checkbox.Group;
const plainOptions = [
  "Nə? Harada? Nə zaman?",
  "Xəmsə",
  "Fərdi Oyun",
  "Breyn Rinq",
  "Erudit-kvartet",
  "OSİP"
];
const defaultCheckedList = [
  "Nə? Harada? Nə zaman?",
  "Xəmsə",
  "Fərdi Oyun",
  "Breyn Rinq",
  "Erudit-kvartet",
  "OSİP"
];

const plainOptionsPlaces = [
  "Sualın mətni",
  "Sualın cavabı",
  "Sualın şərhi",
  "Rekvizitin mətni"
];
const defaultCheckedListPlaces = [
  "Sualın mətni",
  "Sualın cavabı",
  "Sualın şərhi",
  "Rekvizitin mətni"
];

const { Search } = Input;

class QuestionSearch extends React.Component {
  state = {
    checkedList: defaultCheckedList,
    indeterminate: true,
    checkAll: true,
    checkedListPlaces: defaultCheckedListPlaces,
    indeterminatePlaces: true,
    checkAllPlaces: true,
    count: 10,
    showAnswers: false,
    keyword: "",
    from: "2001-01-01",
    to: "2030-01-01",
    elements: []
  };

  handleKeywordChange = e => {
    this.setState({ keyword: e.target.value });
  };

  validateSearch() {
    if (this.state.keyword.trim().length < 3) {
      message.warning("Açar söz daxil edin");
      return true;
    }
    return false;
  }

  searchQuestion = async () => {
    if (this.validateSearch()) {
      return;
    }
    let keywords = [this.state.keyword.trim()];
    var o = {
      limit: this.state.count,
      games: this.state.checkedList.map(c => plainOptions.indexOf(c) + 1),
      places: this.state.checkedListPlaces.map(p =>
        plainOptionsPlaces.indexOf(p)
      ),
      keywords: keywords,
      to: this.state.to,
      from: this.state.from
    };
    this.props.loadingOn();
    await sualtap
      .get(
        `questions/search?limit=${this.state.count}&games=${o.games.join(
          "/-/"
        )}&places=${o.places.join("/-/")}&keywords=${o.keywords.join(
          "/-/"
        )}&to=${o.to}&from=${o.from}`
      )
      .then(res => {
        this.props.setElements(res.data, this.state.showAnswers, keywords);
      });
    this.props.loadingOff();
  };

  handleAnswersChange = () => {
    const val = !this.state.showAnswers;
    this.setState({
      showAnswers: val
    });
  };

  handleCountChange = e => {
    this.setState({
      count: e
    });
  };

  onDateChange = (date, dateString) => {
    this.setState({ to: dateString[1], from: dateString[0] });
  };

  onChange = checkedList => {
    this.setState({
      checkedList,
      indeterminate:
        !!checkedList.length && checkedList.length < plainOptions.length,
      checkAll: checkedList.length === plainOptions.length
    });
  };

  onCheckAllChange = e => {
    this.setState({
      checkedList: e.target.checked ? plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked
    });
  };

  onChangePlaces = checkedListPlaces => {
    this.setState({
      checkedListPlaces,
      indeterminatePlaces:
        !!checkedListPlaces.length &&
        checkedListPlaces.length < plainOptionsPlaces.length,
      checkAllPlaces: checkedListPlaces.length === plainOptionsPlaces.length
    });
  };

  onCheckAllChangePlaces = e => {
    this.setState({
      checkedListPlaces: e.target.checked ? plainOptionsPlaces : [],
      indeterminatePlaces: false,
      checkAllPlaces: e.target.checked
    });
  };

  render() {
    return (
      <>
        <Search
          size="large"
          placeholder="Açar söz"
          value={this.state.keyword}
          onChange={e => this.handleKeywordChange(e)}
          style={{ width: "100%" }}
          onSearch={this.searchQuestion}
        />
        <Row style={{ marginTop: 30 }} gutter={16}>
          <Col style={{ marginTop: 10 }} lg={6} sm={12} xs={24}>
            <div className="checkBoxGroup">
              <div style={{ marginBottom: "10px" }}>
                <Checkbox
                  indeterminate={this.state.indeterminate}
                  onChange={this.onCheckAllChange}
                  checked={this.state.checkAll}
                >
                  Bütün növ suallarda axtar
                </Checkbox>
              </div>
              <CheckboxGroup
                options={plainOptions}
                value={this.state.checkedList}
                onChange={this.onChange}
              />
            </div>
          </Col>
          <Col style={{ marginTop: 10 }} lg={6} sm={12} xs={24}>
            <div className="checkBoxGroup">
              <div style={{ marginBottom: "10px" }}>
                <Checkbox
                  indeterminate={this.state.indeterminatePlaces}
                  onChange={this.onCheckAllChangePlaces}
                  checked={this.state.checkAllPlaces}
                >
                  Sualın bütün hissələrində axtar
                </Checkbox>
              </div>
              <CheckboxGroup
                options={plainOptionsPlaces}
                value={this.state.checkedListPlaces}
                onChange={this.onChangePlaces}
              />
            </div>
          </Col>
          <Col style={{ marginTop: 10 }} lg={12} sm={24} xs={24}>
            <Row>
              <Col lg={12} sm={12} xs={12}>
                <label style={{ float: "left", width: "100%" }}>
                  <b>Nəticə sayı</b>
                </label>
                <InputNumber
                  value={this.state.count}
                  onChange={e => this.handleCountChange(e)}
                  style={{ float: "left" }}
                  min={10}
                  max={120}
                />
              </Col>
              <Col lg={12} sm={12} xs={12}>
                <label style={{ float: "left", width: "100%" }}>
                  <b>Cavabları</b>
                </label>
                <Switch
                  checkedChildren="Göstər"
                  unCheckedChildren="Gizlət"
                  checked={this.state.showAnswers}
                  onChange={() => this.handleAnswersChange()}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }} className="range-picker">
              <label style={{ float: "left", width: "100%" }}>
                <b>Tarix aralığı</b>
              </label>
              <RangePicker
                onChange={(date,dateString) => this.onDateChange(date,dateString)}
                allowClear
                defaultValue={[
                  moment(this.state.from, "YYYY-MM-DD"),
                  moment(this.state.to, "YYYY-MM-DD")
                ]}
              />
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Button
            style={{ width: 300 }}
            type="primary"
            onClick={() => this.searchQuestion()}
          >
            Axtar
          </Button>
        </Row>
      </>
    );
  }
}

export default connect(null, { loadingOff, loadingOn })(QuestionSearch);

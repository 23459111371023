import React from 'react'
import { Icon } from 'antd'
import siteUrl from './../../../const/const'
import { Link } from 'react-router-dom'

class Element extends React.Component {
  toggleOpen(question) {
    this.props.toggleOpen(question)
  }

  renderQuestionType(values, index) {
    switch (values.length) {
      case 2:
        return (
          <>
            <b> Duplet</b>
          </>
        )
      case 3:
        return (
          <>
            <b> Blits</b>
          </>
        )
      case 4:
        return (
          <>
            <b> Kvadriplet</b>
          </>
        )
      default:
        return null
    }
  }

  highlightText = (text) => {
    let el = this.props.keywords[0]
    if (text.toUpperCase().includes(el.toUpperCase())) {
      const index = text.toUpperCase().indexOf(el.toUpperCase())
      return (
        <>
          {text.substring(0, index)}
          <span className="bg-yellow">
            {text.substring(index, index + el.length)}
          </span>
          {text.substring(index + el.length)}
        </>
      )
    }
    return text
  }

  //question, index, opens, toggleOpen

  render() {
    // let keyused = false;
    const q = this.props.question
    const op = this.props.opens.includes(q.values[0].id)
    return (
      <div className="b-question">
        <div className="b-question-item package-name">
          <Link to={`/package/${this.props.package.id}`}>
            {this.props.index + 1}.&nbsp;
            {this.props.package.name}
          </Link>
        </div>
        {
          <div className="b-question-item">
            <span className="font-20">
              {this.renderQuestionType(q.values, this.props.index)}
              {/* {(keyused = q.values.length > 1 ? true : false)} */}
            </span>
          </div>
        }
        {q.notebefore ? (
          <div className="b-question-item">
            <span>{this.highlightText(q.notebefore)}</span>
          </div>
        ) : null}
        {q.isRekvizitForAll ? (
          <div className="b-question-content">
            <p>Paylama materialı</p>
            <span>
              {(
                q.values[0].rekvizit !== null
                  ? q.values[0].rekvizit.text
                  : false
              ) ? (
                <p className="text-rekvizit">
                  {q.values[0].rekvizit.rekvizit
                    .split('/-/')
                    .map((m, index) => (
                      <span key={index}>
                        {this.highlightText(m)}
                        <br />
                      </span>
                    ))}
                </p>
              ) : null}
            </span>
            <p>
              {q.values[0].rekvizit !== null &&
              !(q.values[0].rekvizit !== null
                ? q.values[0].rekvizit.text
                : false) ? (
                <img
                  src={`${siteUrl}images/${q.values[0].rekvizit.rekvizit}`}
                  alt={q.values[0].rekvizit.rekvizit}
                />
              ) : null}
            </p>
          </div>
        ) : null}
        {q.values.map((qv) => {
          const multi = q.values.length > 1
          const before = q.notebefore ? true : false
          //   const mainKey = this.props.index + 1;

          const key = q.values.indexOf(qv) + 1

          const rekOrNot = qv.rekvizit !== null
          const rekTextOrNot = rekOrNot ? qv.rekvizit.text : false

          const main = qv.text.split('/-/')
          let towrite = main.map((m, index) => {
            return (
              <span key={index}>
                {/* {index ? <span>&nbsp;&nbsp;</span> : null} */}
                {this.highlightText(m)}
                <br />
              </span>
            )
          })

          let gettowrite = main.map((m, index) => {
            return (
              <span key={index}>
                {this.highlightText(m)}
                <br />
              </span>
            )
          })

          let rektowrite = null
          if (rekTextOrNot) {
            const rekmain = qv.rekvizit.rekvizit.split('/-/')
            rektowrite = rekmain.map((m, index) => {
              return (
                <span key={index}>
                  {this.highlightText(m)}
                  <br />
                </span>
              )
            })
          }

          const img = qv.rekvizit
            ? `${siteUrl}images/` + qv.rekvizit.rekvizit
            : null
          return (
            <div key={q.values.indexOf(qv)} className="b-question-content">
              {multi ? (
                <span>
                  {rekOrNot && !q.isRekvizitForAll ? (
                    <p>{`${key}. `}Rekvizit</p>
                  ) : (
                    <p>
                      {`${key}. `}
                      {towrite}
                    </p>
                  )}
                </span>
              ) : (
                <>
                  {!before ? (
                    <span>
                      {rekOrNot ? (
                        <p>
                          {/* {`${mainKey}. `} */}
                          <b>Paylama materialı</b>
                        </p>
                      ) : (
                        <p>
                          {/* {`${mainKey}. `} */}
                          {towrite}
                        </p>
                      )}
                    </span>
                  ) : (
                    <span>
                      {rekOrNot ? (
                        <p>{`Paylama materialı`}</p>
                      ) : (
                        <p>{gettowrite}</p>
                      )}
                    </span>
                  )}
                </>
              )}
              {rekTextOrNot && !q.isRekvizitForAll ? (
                <p className="text-rekvizit">{rektowrite}</p>
              ) : null}
              <p>
                {rekOrNot && !q.isRekvizitForAll && !rekTextOrNot ? (
                  <img src={img} alt={qv.rekvizit.rekvizit} />
                ) : null}
              </p>
              <span>
                {rekOrNot && !q.isRekvizitForAll ? <p>{gettowrite}</p> : null}
              </span>
            </div>
          )
        })}
        <div
          onClick={() => this.toggleOpen(q.values[0].id)}
          className="openIcon"
        >
          <Icon
            type="caret-down"
            className={`opicon ${op ? 'rotate' : null}`}
          />
        </div>
        {q.values ? (
          <div className={`b-question-item b-answer ${op ? 'open' : null}`}>
            <span>
              <b>Cavab: </b>
            </span>
            {q.values.length > 1
              ? this.highlightText(`1. ${q.values[0].answer.trim()} `)
              : this.highlightText(q.values[0].answer.trim())}
            {q.values[1]
              ? this.highlightText(` 2. ${q.values[1].answer.trim()} `)
              : null}
            {q.values[2]
              ? this.highlightText(` 3. ${q.values[2].answer.trim()} `)
              : null}
            {q.values[3]
              ? this.highlightText(` 4. ${q.values[3].answer.trim()} `)
              : null}
          </div>
        ) : null}
        {q.considered ? (
          <div className={`b-question-item b-answer ${op ? 'open' : null}`}>
            <span>
              <b>Sayılma meyarı: </b>
            </span>
            {this.highlightText(q.considered)}
          </div>
        ) : null}
        {q.comment ? (
          <div className={`b-question-item b-answer ${op ? 'open' : null}`}>
            <span>
              <b>Şərh: </b>
            </span>
            {this.highlightText(q.comment)}
          </div>
        ) : null}
        {q.sources.length > 0 ? (
          <div className={`b-question-item b-answer ${op ? 'open' : null}`}>
            <p>
              <span>
                <b>Mənbə:</b>&nbsp;
              </span>
              {q.sources.map((qa, index) => {
                return (
                  <React.Fragment key={index}>
                    <span className="break-all">
                      {index > 0 ? (
                        <>
                          <br />{' '}
                          <span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                        </>
                      ) : null}
                      {q.sources.length > 1 ? index + 1 + '. ' + qa : qa}
                    </span>
                  </React.Fragment>
                )
              })}
            </p>
          </div>
        ) : null}
        {q.authors.length > 0 ? (
          <div className={`b-question-item b-answer ${op ? 'open' : null}`}>
            <span>
              <b>{q.authors.length > 1 ? 'Müəlliflər: ' : 'Müəllif: '}</b>
            </span>
            {q.authors.map((aut, index) => (
              <React.Fragment key={index}>
                {index > 0 && index < q.authors.length ? ', ' : ''}
                {aut.fullname}
              </React.Fragment>
            ))}
          </div>
        ) : null}
      </div>
    )
  }
}

export default Element

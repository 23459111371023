import React from "react";
import Theme from "./Single/Theme";

class Themes extends React.Component {
  state = {
    opens: [],
  };

  allOpen() {
    let resp = true;
    if (!this.props.phase) {
      return false;
    }
    this.props.phase.themes.forEach((q) => {
      for (let i = 0; i < 5; i++) {
        if (!this.state.opens.includes(`${q.id}-${i}`)) {
          resp = false;
        }
      }
    });
    return resp;
  }

  toggleAll = () => {
    const show = this.allOpen();
    if (!show) {
      let obj = [...this.state.opens];
      this.props.phase.themes.forEach((q) => {
        obj.push(`${q.id}-0`);
        obj.push(`${q.id}-1`);
        obj.push(`${q.id}-2`);
        obj.push(`${q.id}-3`);
        obj.push(`${q.id}-4`);
      });
      this.setState({
        opens: obj,
      });
    } else {
      this.setState({
        opens: [],
      });
    }
  };

  toggleOpen = (theme, question) => {
    let ope = [...this.state.opens];
    if (this.state.opens.includes(`${theme}-${question}`)) {
      this.setState({
        opens: ope.filter((o) => o !== `${theme}-${question}`),
      });
    } else {
      ope.push(`${theme}-${question}`);
      this.setState({
        opens: ope,
      });
    }
  };

  renderThemes() {
    return (
      <div>
        {this.renderTheme(1)}
        {this.renderTheme(2)}
        {this.renderTheme(3)}
        {this.renderTheme(4)}
        {this.renderTheme(5)}
        {this.renderTheme(null)}
      </div>
    );
  }

  toggleAnswer(theme, question) {
    console.log("Theme is: " + theme);
    console.log("Question is: " + question);
  }

  renderRaundName(type) {
    if (this.props.phase.name !== "Seçim turu") {
      switch (type) {
        case 1:
          return this.props.type ? (
            <h2>
              <b>{this.props.erudit ? "1-ci raund" : "Bəlli raund"}</b>
            </h2>
          ) : (
            <h2>
              <b>Birqat</b>
            </h2>
          );
        case 2:
          return this.props.type ? (
            <h2>
              <b>{this.props.erudit ? "2-ci raund" : "Şərtli raund"}</b>
            </h2>
          ) : (
            <h2>
              <b>İkiqat</b>
            </h2>
          );
        case 3:
          return this.props.type ? (
            <h2>
              <b>{this.props.erudit ? "3-cü raund" : "Sirli raund"}</b>
            </h2>
          ) : (
            <h2>
              <b>Üçqat</b>
            </h2>
          );
        case 4:
          return this.props.type ? (
            <h2>
              <b>Fərdi raund</b>
            </h2>
          ) : (
            <h2>
              <b>Dördqat</b>
            </h2>
          );
        case 5:
          return (
            <h2>
              <b>Xəmsə raundu</b>
            </h2>
          );
        case null:
          return null;
        default:
          return this.props.type ? (
            <h2>
              <b>Bəlli raund</b>
            </h2>
          ) : (
            <h2>
              <b>Birqat</b>
            </h2>
          );
      }
    } else {
      return null;
    }
  }

  renderTheme(raund) {
    return this.props.phase.themes.filter((phas) => phas.raund === raund)
      .length ? (
      <div className="raund">
        {this.renderRaundName(raund)}
        {this.props.phase.themes
          .filter((phas) => phas.raund === raund)
          .map((co) => (
            <Theme
              key={co.id}
              opens={this.state.opens}
              khamsa={raund === 5}
              theme={co}
              toggleOpen={this.toggleOpen}
            />
          ))}
      </div>
    ) : null;
  }

  render() {
    return (
      <>
        <div className="toggleButton" onClick={() => this.toggleAll()}>
          {this.allOpen() ? "Bütün cavabları gizlət" : "Bütün cavabları göstər"}
        </div>
        <div>{this.renderThemes()}</div>
      </>
    );
  }
}

export default Themes;

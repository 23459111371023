import React from "react";
import { Col, Input, Row, Pagination, Button, message } from "antd";
import { Link } from "react-router-dom";
import sualtap from "./../../../const/axios";
import Package from "../../Elements/Package/Package";
import { connect } from "react-redux";
import { loadingOff, loadingOn, setPackages } from "./../../../redux/actions";
import { Helmet } from "react-helmet";
import "./style/style.css";
const { Search } = Input;

class Packages extends React.Component {
  state = {
    packages: [],
    total: 0,
    games: [1, 2, 3, 4, 5, 6, 7],
    keyword: "",
    reserved: {
      games: [1, 2, 3, 4, 5, 6, 7],
      keyword: "",
    },
    tags: "Asan",
  };

  componentDidMount = () => {
    this.searchPackages(1);
  };

  searchPackages = async (page) => {
    this.props.loadingOn();
    await sualtap
      .get(
        `/packages/forhome?page=${page}&games=${this.state.games}&word=${this.state.keyword}`
      )
      .then((res) => {
        this.props.setPackages(res.data.packages);
        this.setState({
          packages: res.data.packages,
          total: res.data.count,
          reserved: {
            games: this.state.games,
            keyword: this.state.keyword,
          },
        });
      });
    this.props.loadingOff();
  };

  paginatePackages = async (page) => {
    this.props.loadingOn();
    await sualtap
      .get(
        `/packages/forhome?page=${page}&games=${this.state.reserved.games}&word=${this.state.reserved.keyword}`
      )
      .then((res) => {
        this.props.setPackages(res.data.packages);
        this.setState({
          packages: res.data.packages,
          total: res.data.count,
        });
      });
    this.props.loadingOff();
  };

  handleKeywordChange = (val) => {
    this.setState({
      keyword: val.target.value,
    });
  };

  deactivateGame = (code) => {
    let games = [...this.state.games];
    if (games.includes(code)) {
      if (games.length > 1) {
        games = games.filter((a) => a !== code);
      }
    } else {
      games = [...games, code];
    }
    this.setState({
      games,
    });
  };

  searchPackage = (page) => {
    if (this.state.keyword.length === 0) {
      message.warning("Açar söz boş olmamalıdır");
    } else {
      this.searchPackages(page);
    }
  };

  render() {
    const packages = this.state.packages.map((item, index) => (
      <Col key={index} lg={8} sm={12} xs={24}>
        <Link to={`/package/${item.id}`}>
          <Package pack={item} free={true} />
        </Link>
      </Col>
    ));
    return (
      <div className="b-container">
        <Helmet>
          <title>3sual.az | Sual paketləri</title>
          <meta name="robots" content="index" />
        </Helmet>
        <div className="page b-game-types pb-0">
          <div className="b-100 mb20">
            <Search
              placeholder="Paketi axtarın"
              value={this.state.keyword}
              onChange={(value) => this.handleKeywordChange(value)}
              onSearch={() => this.searchPackage(1)}
              style={{ height: "40px" }}
            />
          </div>
          <div className="flex jcsb">
            <div className="b-game-type-box">
              <div
                className={`b-game-type ${
                  this.state.games.includes(1) ? "active" : ""
                }`}
                onClick={() => this.deactivateGame(1)}
              >
                Nə? Harada? Nə zaman?
              </div>
              <div
                className={`b-game-type ${
                  this.state.games.includes(2) ? "active" : ""
                }`}
                onClick={() => this.deactivateGame(2)}
              >
                Xəmsə
              </div>
              <div
                className={`b-game-type ${
                  this.state.games.includes(3) ? "active" : ""
                }`}
                onClick={() => this.deactivateGame(3)}
              >
                Fərdi Oyun
              </div>
              <div
                className={`b-game-type ${
                  this.state.games.includes(4) ? "active" : ""
                }`}
                onClick={() => this.deactivateGame(4)}
              >
                Breyn Rinq
              </div>
              <div
                className={`b-game-type ${
                  this.state.games.includes(5) ? "active" : ""
                }`}
                onClick={() => this.deactivateGame(5)}
              >
                Erudit-kvartet
              </div>
              <div
                className={`b-game-type ${
                  this.state.games.includes(6) ? "active" : ""
                }`}
                onClick={() => this.deactivateGame(6)}
              >
                OSİP
              </div>
              <div
                className={`b-game-type ${
                  this.state.games.includes(7) ? "active" : ""
                }`}
                onClick={() => this.deactivateGame(7)}
              >
                Quiz
              </div>
            </div>
            <Button
              type="primary"
              onClick={() => this.searchPackages(1)}
              style={{ height: 40 }}
            >
              Paket axtar
            </Button>
          </div>
          {/* <div className="flex">
            <CheckableTag children={this.state.tags} />
          </div> */}
        </div>
        <div className={`page pb-0 ${this.state.total > 15 ? `mb10` : `mb50`}`}>
          {packages.length > 0 ? (
            <Row gutter={16}>{packages}</Row>
          ) : (
            <span className="no-result">Axtarışa uyğun paket tapılmadı</span>
          )}
        </div>
        {this.state.total > 15 ? (
          <div className="page mb20">
            <Row gutter={16}>
              <Col span={24}>
                <Pagination
                  pageSize={15}
                  onChange={this.paginatePackages}
                  total={this.state.total}
                />
              </Col>
            </Row>
          </div>
        ) : null}
      </div>
    );
  }
}

export default connect(null, { loadingOn, loadingOff, setPackages })(Packages);

import React from "react";
import { Form, Input, Button, Result, notification } from "antd";
import { Link } from "react-router-dom";
import "./style/Forgot.css";
import sualtap from "../../../const/axios";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";
import history from "./../../../const/history";

class Reset extends React.Component {
  state = {
    isGood: false,
  };
  componentDidMount() {
    if (this.props.params.code && this.props.params.email) {
      this.setState({
        isGood: true,
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        await sualtap
          .put(
            "auth/forgot",
            JSON.stringify({ ...this.props.params, ...values })
          )
          .then((res) => {
            if (res.data.success) {
              notification.info({
                message: "Əməliyyat uğurlu oldu",
                description: res.data.message,
                placement: "bottomRight",
                icon: <SmileOutlined />,
              });
              history.push("/");
            } else {
              notification.info({
                message: "Əməliyyat uğursuz oldu",
                description: res.data.message,
                placement: "bottomRight",
                icon: <FrownOutlined />,
              });
            }
          });
        console.log("Received values of form: ", values);
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="b-container page">
        {this.state.isGood ? (
          <div className="loginForm">
            <h3 className="page-name">Şifrəmi yenilə</h3>
            <Form onSubmit={this.handleSubmit} className="login-form reset-form">
              <Form.Item hasFeedback className="reset-item">
                {getFieldDecorator("password", {
                  validateTrigger: "onBlur",
                  rules: [
                    {
                      required: true,
                      message: "Şifrənizi təyin edin.",
                    },
                    {
                      min: 6,
                      message: "Şifrə minimal 6 simvol olmalıdır",
                    },
                    {
                      validator: this.validateToNextPassword,
                    },
                  ],
                })(<Input.Password placeholder="Yeni şifrə" />)}
              </Form.Item>
              <Form.Item hasFeedback className="reset-item">
                {getFieldDecorator("confirm", {
                  validateTrigger: "onBlur",
                  rules: [
                    {
                      required: true,
                      message: "Şifrənizi təsdiqləyin!",
                    },
                    {
                      validator: this.compareToFirstPassword,
                      message: "Daxil etdiyiniz şifrələr eyni deyil",
                    },
                  ],
                })(
                  <Input.Password
                    onBlur={this.handleConfirmBlur}
                    placeholder="Şifrənizi təsdiqləyin"
                  />
                )}
              </Form.Item>
              <Form.Item className="forgot-form-row">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{ width: "100%" }}
                >
                  Şifrəmi dəyiş
                </Button>
              </Form.Item>
            </Form>
          </div>
        ) : (
          <Result
            status="404"
            title="404"
            subTitle="Axtardığın səhifəni tapmadıq."
            extra={
              <Button type="primary">
                <Link to="/">Əsas səhifə</Link>
              </Button>
            }
          />
        )}
      </div>
    );
  }
}

const WrappedNormalForgotForm = Form.create({ name: "normal_reset" })(Reset);

export default WrappedNormalForgotForm;

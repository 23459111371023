import React from "react";
import sualtap from "../../../const/axios";
import Theme from "./../Package/Single/Theme";
import Quiz from "./../Package/Single/Quiz";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { loadingOn, loadingOff } from "./../../../redux/actions";
import { Pagination } from "antd";
import { Helmet } from "react-helmet";
import "./style/Authors.css";
import history from './../../../const/history';

class AuthorThemes extends React.Component {
  state = {
    content: [],
    user: "",
    opens: [],
    isEmpty: false,
    count: 0,
    page: 1
  };

  allOpen() {
    let resp = true;
    this.state.content.forEach((qu) => {
      qu.themes.forEach((q) => {
        if (
          !this.state.opens.includes(`${q.id}-0`) ||
          !this.state.opens.includes(`${q.id}-1`) ||
          !this.state.opens.includes(`${q.id}-2`) ||
          !this.state.opens.includes(`${q.id}-3`) ||
          !this.state.opens.includes(`${q.id}-4`)
        ) {
          resp = false;
        }
      });
    });
    return resp;
  }

  toggleAll = () => {
    const show = this.allOpen();
    if (!show) {
      let obj = [...this.state.opens];
      this.state.content.forEach((element) => {
        element.themes.forEach((q) => {
          obj.push(`${q.id}-0`);
          obj.push(`${q.id}-1`);
          obj.push(`${q.id}-2`);
          obj.push(`${q.id}-3`);
          obj.push(`${q.id}-4`);
        });
      });
      this.setState({
        opens: obj,
      });
    } else {
      this.setState({
        opens: [],
      });
    }
  };

  toggleOpen = (theme, question) => {
    let ope = [...this.state.opens];
    if (this.state.opens.includes(`${theme}-${question}`)) {
      this.setState({
        opens: ope.filter((o) => o !== `${theme}-${question}`),
      });
    } else {
      ope.push(`${theme}-${question}`);
      this.setState({
        opens: ope,
      });
    }
  };

  fetchThemes = async (page) => {
    this.props.loadingOn();
    await sualtap
      .get(`authors/themes?id=${this.props.params.id}&page=${page}&perpage=20`)
      .then((res) => {
        console.log(res.data.results);
        this.setState({
          content: res.data.results,
          user: res.data.fullname,
          count: res.data.count,
          page
        });
      });
    this.props.loadingOff();
  };

  paginateAuthorThemes = (a) => {
    this.fetchThemes(a);
    history.push(`/author/themes/${this.props.params.id}/${a}`)
  };

  detectPageAndFetchThemes(){
    let page = 1;
    if(this.props.params.page){
      page = +this.props.params.page;
    }
    this.fetchThemes(page);
  }

  componentDidMount = async () => {
    this.detectPageAndFetchThemes();
    history.listen((location,action)=>{
      if(action==='POP'){
        this.detectPageAndFetchThemes();
      }
    })
  };

  renderQuestions() {
    return this.state.content.map((co, qindex) => {
      return (
        <div className="author-questions-section" key={qindex}>
          <span className="package-name">
            {co.isReady ? (
              <Link to={`/package/${co.id}`}>
                {co.name} - {co.game}
              </Link>
            ) : (
              <Link to={`/author/themes/${this.props.params.id}`}>
                {co.name} - {co.game}
              </Link>
            )}
          </span>
          {co.themes.map((t, index) => {
            if (co.game !== "Quiz") {
              return (
                <Theme
                  key={index}
                  opens={this.state.opens}
                  theme={t}
                  index={index}
                  toggleOpen={this.toggleOpen}
                />
              );
            } else {
              return (
                <Quiz
                  key={index}
                  opens={this.state.opens}
                  theme={t}
                  index={index}
                  toggleOpen={this.toggleOpen}
                />
              );
            }
          })}
        </div>
      );
    });
  }

  render() {
    return (
      <div className="b-container page">
        <Helmet>
          <title>{this.state.user} | Mövzular</title>
        </Helmet>
        <div className="top-layer bg-white flex jcsb">
          <h2 className="bold">
            {this.state.user} ({this.state.count})
          </h2>
          <h2 onClick={() => this.toggleAll()} className="bold openAnswers">
            {this.allOpen()
              ? "Bütün cavabları gizlət"
              : "Bütün cavabları göstər"}
          </h2>
        </div>
        <div className="top-layer bg-white">
          {this.state.content.length !== 0 ? this.renderQuestions() : null}
          {this.state.isEmpty ? (
            <span>Bu müəllifin sualları əlavə edilməyib</span>
          ) : null}
        </div>
        {!this.state.isEmpty ? (
          <div className="b-100 flex end">
            <Pagination
              onChange={this.paginateAuthorThemes}
              current={this.state.page}
              pageSize={20}
              total={this.state.count}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default connect(null, { loadingOff, loadingOn })(AuthorThemes);

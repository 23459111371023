import React, { useEffect } from 'react';
// import Question from "./Question";

export const Phase = (props) => {
    useEffect(() => {
        // console.log(props.phase)
    }, [props])

    return (
        <div>
            Salam
        </div>
    )
}
import React from 'react';
import {Input, Button, message} from 'antd';
import './style/style.css';
import sualtap from '../../../const/axios';
import {connect} from 'react-redux';
const {TextArea} = Input;

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      name: '',
      surname: '',
      email: '',
      phone: '',
      isHandled: false,
      message: '',
      topic: '',
    };
  }

  componentDidMount() {
    if (this.props.user.isLoggedIn) {
      this.setState({
        name: this.props.user.data.name,
        surname: this.props.user.data.surname,
        email: this.props.user.data.email,
        phone: this.props.user.data.phone,
      });
    }
  }

  handleTextChange = ({target: {value, name}}) => {
    const newState = {...this.state};
    newState[name] = value;
    this.setState(newState);
  };

  validateContact = () => {
    let arr = [];
    !this.state.name.length ? arr.push('Adınızı') : arr.push('');
    !this.state.surname.length ? arr.push('Soyadınızı') : arr.push('');
    !this.state.email.length && !this.state.phone.length < 10
      ? arr.push('Emailinizi və ya əlaqə nömrənizi')
      : arr.push('');
    !this.state.topic.length ? arr.push('Mövzunu') : arr.push('');
    this.state.message.length < 20 ? arr.push('Mesajınızı ətraflı') : arr.push('');
    arr
      .filter((a) => a !== '')
      .forEach((a) => {
        message.warning(`${a} daxil edin`);
      });
    return arr.filter((a) => a !== '').length < 1;
  };
  submitContact = () => {
    if (this.validateContact()) {
      sualtap.post('contacts', {...this.state}).then((res) => {
        message.success('Mesajınız uğurla göndərildi!');
        this.setState(this.getInitialState());
      });
    }
  };

  render() {
    return (
      <div className='b-contact'>
        <div className='overlay'></div>
        <div className='contact-form'>
          <div className='two-form'>
            <h1>Bİzə yazın</h1>
          </div>
          <div className='two-form'>
            <Input
              size='large'
              placeholder='Ad'
              value={this.state.name}
              name='name'
              onChange={this.handleTextChange}
              className='one-two-form'
              disabled={this.props.user.isLoggedIn}
            />
            <Input
              size='large'
              placeholder='Soyad'
              value={this.state.surname}
              name='surname'
              onChange={this.handleTextChange}
              className='one-two-form'
              disabled={this.props.user.isLoggedIn}
            />
          </div>
          <div className='two-form'>
            <Input
              size='large'
              placeholder='Email'
              value={this.state.email}
              name='email'
              onChange={this.handleTextChange}
              className='one-two-form'
              disabled={this.props.user.isLoggedIn}
            />
            <Input
              size='large'
              placeholder='Əlaqə nömrəsi'
              value={this.state.phone}
              name='phone'
              onChange={this.handleTextChange}
              className='one-two-form'
              disabled={this.props.user.isLoggedIn}
            />
          </div>
          <div className='two-form'>
            <Input
              size='large'
              placeholder='Mövzu'
              name='topic'
              onChange={this.handleTextChange}
              value={this.state.topic}
            />
          </div>
          <div className='two-form'>
            <TextArea
              rows={6}
              placeholder='Mesajınız'
              value={this.state.message}
              name='message'
              onChange={this.handleTextChange}
            ></TextArea>
          </div>
          <Button
            onClick={() => this.submitContact()}
            type='primary'
            className='float-r'
            size='large'
          >
            Göndər
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({user}) => {
  return {
    user,
  };
};

export default connect(mapStateToProps, null)(Contact);

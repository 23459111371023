import React, { useEffect, useState } from "react";
import { Result, Button } from "antd";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import sualtap from "../../../const/axios";

export const Confirm = (props) => {
  const [text, setText] = useState("Bu səhifə mövcud deyil");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const confirmUser = async () => {
      if (props.params.code !== undefined && props.params.email !== undefined) {
        await sualtap
          .put("auth/confirm", {
            email: props.params.email,
            code: props.params.code,
          })
          .then((res) => {
            setText(res.data.message);
            setSuccess(res.data.success);
          });
      }
    };
    confirmUser();
  }, [props.params.code, props.params.email]);

  return (
    <div className="page bg-white result-element">
      <Result
        icon={success ? <SmileOutlined /> : <FrownOutlined />}
        title={text}
        extra={
          <Link to="/">
            <Button type="primary">Əsas səhifəyə qayıt</Button>
          </Link>
        }
      />
    </div>
  );
};

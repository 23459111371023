import React, { useEffect, useState } from 'react'

const basic = { name: '', point: 0 }
const Calculate = () => {
  const [players, setPlayers] = useState([basic])

  const handleChange = (value, type, index) => {
    const pps = [...players]
    pps[index][type] = value
    save(pps)
  }

  const save = (value) => {
    localStorage.setItem('players', JSON.stringify(value))
    setPlayers(value)
  }

  const reset = () => {
    save([basic])
  }
  const remove = (index) => {
    const pps = [...players]
    pps.splice(index, 1)
    save(pps)
  }

  useEffect(() => {
    const ps = localStorage.getItem('players')
    setPlayers(ps ? JSON.parse(ps) : [basic])
  }, [])

  const addPlayer = () => {
    const pps = [...players]
    pps.push(basic)
    save(pps)
  }
  return (
    <div style={{ maxWidth: '500px' }}>
      <button>Calculate</button>
      <button onClick={addPlayer}>Add player</button>
      <button onClick={reset}>Reset</button>
      <table>
        <thead>
          <tr>
            <th>Hesabla</th>
            <th>Xal</th>
            <th>Sil</th>
          </tr>
        </thead>
        <tbody>
          {players.map((player, index) => {
            return (
              <tr>
                <td>
                  <input
                    onChange={(e) => {
                      handleChange(e.target.value, 'name', index)
                    }}
                    value={player.name}
                    type="string"
                  />
                </td>
                <td>
                  <input
                    onChange={(e) => {
                      handleChange(e.target.value, 'point', index)
                    }}
                    value={player.point}
                    type="number"
                  />
                </td>
                <td>
                  <button
                    onClick={() => {
                      remove(index)
                    }}
                  >
                    Sil
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Calculate

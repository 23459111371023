import React from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Tooltip,
  Icon,
  Select,
  notification,
  Result,
  Button,
  Spin,
} from "antd";
import "./style/Register.css";
import sualtap from "./../../../const/axios";
import { FrownOutlined, SmileOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
const { Option } = Select;

class Register extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    done: false,
    text: "",
    loading: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        const obj = {
          ...values,
          phone: values.phone ? values.prefix + values.phone : null,
        };
        await sualtap.post("auth/register", JSON.stringify(obj)).then((res) => {
          if (!res.data.success) {
            notification.info({
              message: "Əməliyyat uğursuz oldu",
              description: res.data.message,
              placement: "topRight",
              icon: <FrownOutlined />,
            });
          } else {
            this.setState({
              done: true,
              text: res.data.message,
            });
          }
          this.setState({
            loading: false,
          });
        });
      }
    });
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  checkForWhitespace = (rule, value, callback) => {
    if (value && value.includes(" ")) {
      callback("İstifadəçi adında boşluq ola bilməz");
    }
    callback();
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    const prefixSelector = getFieldDecorator("prefix", {
      initialValue: "99450",
    })(
      <Select style={{ width: 70 }}>
        <Option value="99450">050</Option>
        <Option value="99451">051</Option>
        <Option value="99455">055</Option>
        <Option value="99470">070</Option>
        <Option value="99477">077</Option>
      </Select>
    );

    return !this.state.done ? (
      <div className="b-container page">
        <h3 className="page-name">Qeydiyyatdan keç</h3>
        <div className="b-Register-Form">
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item label={<span>Adınız</span>}>
                  {getFieldDecorator("name", {
                    validateTrigger: "onBlur",
                    rules: [
                      {
                        required: true,
                        message: "Adınızı daxil edin",
                        whitespace: true,
                      },
                      {
                        min: 2,
                        message: "Ad minimum 2 simvoldan ibarət olmalıdır",
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label={<span>Soyadınız</span>}>
                  {getFieldDecorator("surname", {
                    validateTrigger: "onBlur",
                    rules: [
                      {
                        required: true,
                        message: "Soyadınızı daxil edin",
                        whitespace: true,
                      },
                      {
                        min: 2,
                        message: "Soyad minimum 2 simvoldan ibarət olmalıdır",
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item
                  label={
                    <span>
                      İstifadəçi adınız&nbsp;
                      <Tooltip title="Oxşar adlardan fərqlənmək üçün istifadəçi adı seçin">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator("username", {
                    validateTrigger: "onBlur",
                    rules: [
                      {
                        required: true,
                        message: "İstifadəçi adınızı daxil edin",
                        whitespace: true,
                      },
                      {
                        min: 5,
                        message: "Minimum 5 simvol olmalıdır",
                      },
                      {
                        validator: this.checkForWhitespace,
                        message: "İstifadəçi adında boşluq ola bilməz",
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="E-mail">
                  {getFieldDecorator("email", {
                    validateTrigger: "onBlur",
                    rules: [
                      {
                        type: "email",
                        message: "Düzgün elektron poçt daxil edin",
                      },
                      {
                        required: true,
                        message: "Elektron poçtunuzu daxil edin",
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item label="Şifrəniz" hasFeedback>
                  {getFieldDecorator("password", {
                    validateTrigger: "onBlur",
                    rules: [
                      {
                        required: true,
                        message: "Şifrənizi təyin edin.",
                      },
                      {
                        min: 6,
                        message: "Şifrə minimal 6 simvol olmalıdır",
                      },
                      {
                        validator: this.validateToNextPassword,
                      },
                    ],
                  })(<Input.Password />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="Şifrənizi təsdiqləyin" hasFeedback>
                  {getFieldDecorator("confirm", {
                    validateTrigger: "onBlur",
                    rules: [
                      {
                        required: true,
                        message: "Şifrənizi təsdiqləyin!",
                      },
                      {
                        validator: this.compareToFirstPassword,
                        message: "Daxil etdiyiniz şifrələr eyni deyil",
                      },
                    ],
                  })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item label="Əlaqə nömrəniz">
                  {getFieldDecorator("phone", {
                    validateTrigger: "onBlur",
                    rules: [{ required: false }],
                  })(
                    <Input
                      type="number"
                      addonBefore={prefixSelector}
                      style={{ width: "100%" }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item {...tailFormItemLayout} className="b-submit-btn">
                  <Button type="primary" htmlType="submit">
                    Qeydiyyatı tamamla
                    {this.state.loading ? (
                      <Spin size="small" className="b-spinner" />
                    ) : null}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    ) : (
      <div className="page bg-white result-element">
        <Result
          icon={<SmileOutlined />}
          title={this.state.text}
          extra={
            <Link to="/">
              <Button type="primary">Əsas səhifəyə qayıt</Button>
            </Link>
          }
        />
      </div>
    );
  }
}

const WrappedRegistrationForm = Form.create({ name: "register" })(Register);

export default WrappedRegistrationForm;

import React from "react";
import "./style/Package.css";
import siteUrl from "./../../../const/const";
import { connect } from "react-redux";
import { loadingOff, loadingOn } from "./../../../redux/actions";

const months = [
  "yanvar",
  "fevral",
  "mart",
  "aprel",
  "may",
  "iyun",
  "iyul",
  "avqust",
  "sentyabr",
  "oktyabr",
  "noyabr",
  "dekabr",
];

class Package extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overlay: false,
    };
  }

  hoverCard = () => {
    this.setState({ overlay: true });
  };
  hoverOutCard = () => {
    this.setState({ overlay: false });
  };

  renderDate(date) {
    let da = new Date(date);
    return da.getDate() + " " + months[da.getMonth()] + ", " + da.getFullYear();
  }

  render() {
    const element = this.props.free ? (
      <div>
        <h4>{this.props.pack.name}</h4>
        <p>{this.props.pack.game}</p>
        <small className="packageDates">
          {this.renderDate(this.props.pack.date)}
        </small>
        <img
          className="packageOrganizerAvatar"
          alt="just an ime"
          src={`${siteUrl}images/logos/${this.props.pack.organization.logo}`}
        />
      </div>
    ) : (
      <div className="packagesSeeAll">
        <h4>Bütün paketlərə bax</h4>
      </div>
    );

    return (
      <div
        onMouseLeave={this.hoverOutCard}
        onMouseEnter={this.hoverCard}
        className="Package"
      >
        {element}
      </div>
    );
  }
}

export default connect(null, { loadingOn, loadingOff })(Package);

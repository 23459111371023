import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

class NotFound extends React.Component {
  render() {
    return (
      <div className="page bg-white result-element">
        <Result
          status="404"
          title="404"
          subTitle="Axtardığınız səhifə tapılmadı."
          extra={
            <Button type="primary">
              <Link to="/">Əsas səhifəyə qayıt</Link>
            </Button>
          }
        />
      </div>
    );
  }
}

export default NotFound;

import React from "react";
import sualtap from "../../../const/axios";
import Question from "./../Package/Single/Question";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { loadingOn, loadingOff } from "./../../../redux/actions";
import { Pagination } from "antd";
import { Helmet } from "react-helmet";
import './style/Authors.css';
import history from './../../../const/history';


class AuthorPage extends React.Component {
  state = {
    content: [],
    user: "",
    opens: [],
    isEmpty: false,
    count: 0,
    page: 1
  };

  allOpen() {
    let resp = true;
    this.state.content.forEach(qu => {
      qu.questions.forEach(q => {
        if (!this.state.opens.includes(q.values[0].id)) {
          resp = false;
        }
      });
    });
    return resp;
  }
  toggleAll = () => {
    const show = this.allOpen();
    if (!show) {
      let arr = [...this.state.opens];
      this.state.content.forEach(qu => {
        qu.questions.forEach(q => {
          arr.push(q.values[0].id);
        });
      });
      this.setState({
        opens: arr
      });
    } else {
      this.setState({
        opens: []
      });
    }
  };

  toggleOpen = question => {
    let ope = [...this.state.opens];
    if (this.state.opens.includes(question)) {
      this.setState({
        opens: ope.filter(o => o !== question)
      });
    } else {
      ope.push(question);
      this.setState({
        opens: ope
      });
    }
  };

  fetchQuestions = async page => {
    this.props.loadingOn();
    await sualtap
      .get(
        `authors/questions?id=${this.props.params.id}&page=${page}&perpage=30`
      )
      .then(res => {
        this.setState({
          content: res.data.results,
          user: res.data.fullname,
          count: res.data.count,
          page
        });
      });
    this.props.loadingOff();
  };

  paginateAuthorQuestions = a => {
    this.fetchQuestions(a);
    history.push(`/author/questions/${this.props.params.id}/${a}`)
  };

  detectPageAndFetchQuestions() {
    let page = 1;
    if (this.props.params.page) {
      page = +this.props.params.page;
    }
    this.fetchQuestions(page);
  }

  componentDidMount = async () => {
    this.detectPageAndFetchQuestions();
    history.listen((location, action) => {
      if (action === 'POP') {
        this.detectPageAndFetchQuestions();
      }
    })
  };

  renderQuestions() {
    return this.state.content.map((co, qindex) => {
      return (
        <div className="author-questions-section" key={qindex}>
          <span className="package-name">
            {co.isReady ? (
              <Link to={`/package/${co.id}`}>
                {co.name} - {co.game}
              </Link>
            ) : (
                <Link to={`/author/questions/${this.props.params.id}`}>
                  {co.name} - {co.game}
                </Link>
              )}
          </span>
          {co.questions.map((q, index) => (
            <Question
              key={index}
              opens={this.state.opens}
              question={q}
              index={index}
              toggleOpen={this.toggleOpen}
            />
          ))}
        </div>
      );
    });
  }

  render() {
    return (
      <div className="b-container page">
        <Helmet>
          <title>{this.state.user} | Suallar</title>
        </Helmet>
        <div className="top-layer bg-white flex jcsb">
          <h2 className="bold">
            {this.state.user} ({this.state.count})
          </h2>
          <h2 onClick={() => this.toggleAll()} className="bold openAnswers">
            {this.allOpen()
              ? "Bütün cavabları gizlət"
              : "Bütün cavabları göstər"}
          </h2>
        </div>
        <div className="top-layer bg-white">
          {this.state.content.length !== 0 ? this.renderQuestions() : null}
          {this.state.isEmpty ? (
            <span>Bu müəllifin sualları əlavə edilməyib</span>
          ) : null}
        </div>
        {!this.state.isEmpty ? (
          <div className="b-100 flex end">
            <Pagination
              onChange={this.paginateAuthorQuestions}
              defaultCurrent={1}
              current={this.state.page}
              pageSize={30}
              total={this.state.count}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default connect(null, { loadingOff, loadingOn })(AuthorPage);

import React from "react";
import sualtap from "../../../const/axios";
import "./style/style.css";
import {
  Form,
  Row,
  Col,
  Input,
  Result,
  Button,
  Modal,
  Select,
  Tabs,
  Spin,
  notification,
} from "antd";
import {
  FacebookOutlined,
  PhoneOutlined,
  MailOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Uploader from "./Uploader";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  loadingOn,
  loadingOff,
  setUserPhoto,
  getProfile,
  setProfile,
} from "./../../../redux/actions";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";
import siteUrl from "./../../../const/const";
import history from "./../../../const/history";
const { Option } = Select;
const { TabPane } = Tabs;
let unlisten = null;

class Profile extends React.Component {
  state = {
    uploadPhoto: false,
    imageUrl: "",
    userData: null,
    ownProfile: false,
    error: false,
    modal1Visible: false,
    modal2Visible: false,
    displaySave: false,
    loading: false,
    authorValue: null,
    authors: [],
    pageChanged: false,
  };

  linkAuthor = async () => {
    if (this.state.authorValue) {
      let author = this.state.authors.find(
        (a) => a.id === this.state.authorValue
      );
      var obj = {
        email: this.props.user.data.email,
        isHandled: false,
        message: `${this.props.user.data.id} ID-yə sahib, ${this.props.user.data.name} ${this.props.user.data.surname} adlı istifadəçi və ${this.state.authorValue} ID-yə sahib, ${author.fullname} adlı müəllifə bağlanılmalıdır. `,
        name: this.props.user.data.name,
        phone: this.props.user.data.phone,
        surname: this.props.user.data.surname,
        topic: "Müəllif təyini",
      };
      await sualtap.post("contacts", JSON.stringify(obj)).then((res) => {
        notification.info({
          message: "Təşəkkürlər!",
          description: "Müraciətinizə ən qısa zamanda baxılacaq",
          placement: "topRight",
          icon: <SmileOutlined />,
        });
      });
      this.setModal2Visible(false);
    } else {
      notification.info({
        message: "Seçim etməmisiniz",
        description:
          "Sualları öz adınıza keçirmək üçün ilk öncə müəllif seçməlisiniz.",
        placement: "topRight",
        icon: <FrownOutlined />,
      });
    }
  };

  handleSearch = async (value) => {
    await sualtap.get(`authors?search=${value}`).then((res) => {
      this.setState({
        authors: res.data,
      });
    });
  };

  handleChange = (value) => {
    this.setState({ authorValue: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      ["name", "surname", "username", "email", "phone", "prefix"],
      async (err, values) => {
        if (!err) {
          this.setState({
            loading: true,
          });
          const obj = {
            ...values,
            id: this.props.profile.id,
            phone: values.phone ? values.prefix + values.phone : null,
          };
          await sualtap
            .put(`users/${this.props.profile.id}`, JSON.stringify(obj))
            .then((res) => {
              if (res.data.success) {
                notification.info({
                  message: "Əməliyyat uğurlu oldu",
                  description: res.data.message,
                  placement: "topRight",
                  icon: <SmileOutlined />,
                });
                let changedData = { ...this.props.profile };
                changedData.name = values.name;
                changedData.surname = values.surname;
                changedData.email = values.email;
                changedData.phone = obj.phone;
                changedData.username = values.username;
                this.setState({
                  modal1Visible: false,
                });
                this.props.setProfile(changedData);
              } else {
                notification.info({
                  message: "Əməliyyat uğursuz oldu",
                  description: res.data.message,
                  placement: "topRight",
                  icon: <FrownOutlined />,
                });
              }
              this.setState({
                loading: false,
              });
            });
        }
      }
    );
  };

  cancelPhotoChange = () => {
    this.setState({
      displaySave: false,
      uploadPhoto: false,
      imageUrl: "",
    });
  };
  saveUserPhoto = async () => {
    this.setState({
      loading: true,
    });
    await sualtap
      .put(
        "users/photo",
        JSON.stringify({
          id: this.props.profile.id,
          filename: this.state.imageUrl,
        })
      )
      .then((res) => {
        if (res.data.success) {
          notification.info({
            message: "Əməliyyat uğurlu oldu",
            description: res.data.message,
            placement: "topRight",
            icon: <SmileOutlined />,
          });
          let changedUser = { ...this.props.profile };
          changedUser.photo = `${siteUrl}images/users/${this.state.imageUrl}`;
          this.props.setUserPhoto(changedUser.photo);
          this.props.setProfile(changedUser);
          this.cancelPhotoChange();
        } else {
          notification.info({
            message: "Əməliyyat uğursuz oldu",
            description: res.data.message,
            placement: "topRight",
            icon: <FrownOutlined />,
          });
          this.cancelPhotoChange();
        }
        this.setState({
          loading: false,
        });
      });
  };

  handleSubmit2 = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      ["current", "password"],
      async (err, values) => {
        if (!err) {
          this.setState({
            loading: true,
          });
          const obj = {
            ...values,
            id: this.props.profile.id,
          };
          await sualtap
            .put("users/password", JSON.stringify(obj))
            .then((res) => {
              if (res.data.success) {
                notification.info({
                  message: "Əməliyyat uğurlu oldu",
                  description: res.data.message,
                  placement: "topRight",
                  icon: <SmileOutlined />,
                });
                this.setState({
                  modal1Visible: false,
                });
              } else {
                notification.info({
                  message: "Əməliyyat uğursuz oldu",
                  description: res.data.message,
                  placement: "topRight",
                  icon: <FrownOutlined />,
                });
              }
              this.setState({
                loading: false,
              });
            });
        }
      }
    );
  };

  photoIsUploaded = (imageUrl) => {
    this.setState({
      uploadPhoto: false,
      imageUrl,
      displaySave: true,
    });
  };

  uploadPhoto = (uploadPhoto) => {
    this.setState({
      uploadPhoto,
    });
  };

  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }
  setModal2Visible(modal2Visible) {
    this.setState({ modal2Visible });
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  checkForWhitespace = (rule, value, callback) => {
    if (value && value.includes(" ")) {
      callback("İstifadəçi adında boşluq ola bilməz");
    }
    callback();
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  getProfile = () => {
    if (
      this.props.user.isLoggedIn &&
      (this.props.params.username === undefined ||
        this.props.params.username === this.props.user.data.username)
    ) {
      this.setState({
        ownProfile: true,
      });
      this.props.getProfile(true, null);
    } else if (this.props.params.username !== undefined) {
      this.props.getProfile(false, this.props.params.username);
    } else {
      this.setState({
        error: true,
      });
    }
  };

  componentWillUnmount() {
    unlisten();
  }

  componentDidMount = () => {
    this.getProfile();
    unlisten = history.listen((location, action) => {
      if (location.pathname === "/profile") {
        this.props.setProfile({ error: false });
        this.props.params.username = undefined;
        this.getProfile();
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const prefixSelector = getFieldDecorator("prefix", {
      initialValue:
        this.props.profile && this.props.profile.phone
          ? this.props.profile.phone.substring(0, 5)
          : "99450",
    })(
      <Select style={{ width: 70 }}>
        <Option value="99450">050</Option>
        <Option value="99451">051</Option>
        <Option value="99455">055</Option>
        <Option value="99470">070</Option>
        <Option value="99477">077</Option>
      </Select>
    );
    return !this.props.profile.error && !this.state.error ? (
      <div className="b-container profile page">
        <div className="profile-left bg-white">
          <div className="b-100 fl flex center">
            {!this.state.uploadPhoto ? (
              <div className="profile-img-holder">
                {this.state.ownProfile ? (
                  <span
                    className="uploader-hover"
                    onClick={() => this.uploadPhoto(true)}
                  >
                    <UploadOutlined
                      style={{ fontSize: "25px", color: "white" }}
                    />
                  </span>
                ) : null}
                <img
                  className="profile-img"
                  alt="profile img"
                  src={
                    this.state.imageUrl !== ""
                      ? `${siteUrl}images/temp/${this.state.imageUrl}`
                      : this.props.profile && this.props.profile.photo
                      ? this.props.profile.photo
                      : `${siteUrl}images/avatar.jpg`
                  }
                />
              </div>
            ) : (
              <Uploader imageUpload={this.photoIsUploaded} />
            )}
          </div>
          <div className="b-100 fl profile-name">
            <h3 className="bold">
              {this.props.profile
                ? `${this.props.profile.name} ${this.props.profile.surname}`
                : null}
            </h3>
          </div>
          <div className="b-100 fl profile-username">
            <h4 className="bold">
              @{this.props.profile ? `${this.props.profile.username}` : null}
            </h4>
          </div>
          {this.props.profile && this.props.profile.facebook ? (
            <Link to={this.props.profile.facebook}>
              <div className="b-100 fl profile-social">
                <FacebookOutlined size={"large"} />
              </div>
            </Link>
          ) : null}
        </div>
        {this.state.ownProfile ? (
          this.state.uploadPhoto ? (
            <div className="profile-left edit-line-buttons mobile-994">
              <Button
                type="primary"
                className={
                  !this.state.displaySave ? "b-100-button big-btn" : "big-btn"
                }
                onClick={() =>
                  this.state.displaySave
                    ? this.cancelPhotoChange()
                    : this.uploadPhoto(false)
                }
              >
                Ləğv et
              </Button>
              {this.state.displaySave ? (
                <Button type="primary" onClick={() => this.saveUserPhoto()}>
                  Yadda saxla
                  {this.state.loading ? (
                    <Spin size="small" className="b-spinner" />
                  ) : null}
                </Button>
              ) : null}
            </div>
          ) : null
        ) : null}
        <div className="profile-right">
          <div className="author-info page bg-white">
            <Link
              to={
                this.props.profile &&
                this.props.profile.author &&
                this.props.profile.author.editor
                  ? `/editor/packages/${this.props.profile.author.id}`
                  : "#"
              }
            >
              <div className="badge">
                <span className="badge-name">Redaktə</span>
                <span className="badge-count">
                  {this.props.profile && this.props.profile.author
                    ? this.props.profile.author.editor
                    : 0}
                </span>
              </div>
            </Link>
            <Link
              to={
                this.props.profile &&
                this.props.profile.author &&
                this.props.profile.author.nhn
                  ? `/author/questions/${this.props.profile.author.id}`
                  : "#"
              }
            >
              <div className="badge">
                <span className="badge-name">Nə? Harada? Nə zaman?</span>
                <span className="badge-count">
                  {this.props.profile && this.props.profile.author
                    ? this.props.profile.author.nhn
                    : 0}
                </span>
              </div>
            </Link>
            <Link
              to={
                this.props.profile &&
                this.props.profile.author &&
                this.props.profile.author.theme
                  ? `/author/themes/${this.props.profile.author.id}`
                  : "#"
              }
            >
              <div className="badge">
                <span className="badge-name">Mövzu</span>
                <span className="badge-count">
                  {this.props.profile && this.props.profile.author
                    ? this.props.profile.author.theme
                    : 0}
                </span>
              </div>
            </Link>
            <div className="badge">
              <span className="badge-name">Breyn Rinq</span>
              <span className="badge-count">
                {this.props.profile && this.props.profile.author
                  ? this.props.profile.author.other
                  : 0}
              </span>
            </div>
          </div>
          <div className="info-line-two">
            <div className="basic-info half-section bg-white page">
              <div className="b-100 info-line">
                <MailOutlined />
                <span className="info-text">
                  {this.props.profile ? `${this.props.profile.email}` : null}
                </span>
              </div>
              <div className="b-100 info-line">
                <PhoneOutlined />
                <span className="info-text">
                  {this.props.profile
                    ? `${
                        this.props.profile.phone
                          ? this.props.profile.phone
                          : "XXXXXXXX"
                      }`
                    : null}
                </span>
              </div>
            </div>
            <div className="teams-info half-section bg-white page">
              {this.props.profile &&
              this.props.profile.teams &&
              this.props.profile.teams.length ? (
                <>
                  <table>
                    <tbody>
                      <tr className="team-box">
                        <td>Berserk</td>
                        <td>3 oyunçu</td>
                        {/* <td>
                      <Button type="primary" size="small">
                        Bax
                      </Button>
                    </td> */}
                      </tr>
                      <tr className="team-box">
                        <td>Jagermeister</td>
                        <td>3 oyunçu</td>
                        {/* <td>
                      <Button type="primary" size="small">
                        Bax
                      </Button>
                    </td> */}
                      </tr>
                    </tbody>
                  </table>
                  <Button
                    type="primary"
                    className="fr mt10"
                    style={{ fontSize: "20px" }}
                  >
                    +
                  </Button>
                </>
              ) : (
                <span>Tezliklə buradan öz komandanızı yarada biləcəksiz.</span>
              )}
            </div>
          </div>
        </div>
        {this.state.ownProfile ? (
          this.state.uploadPhoto || this.state.displaySave ? (
            <div className="profile-left edit-line-buttons web-994">
              <Button
                type="primary"
                className={
                  !this.state.displaySave ? "b-100-button big-btn" : "big-btn"
                }
                onClick={() =>
                  this.state.displaySave
                    ? this.cancelPhotoChange()
                    : this.uploadPhoto(false)
                }
              >
                Ləğv et
              </Button>
              {this.state.displaySave ? (
                <Button
                  type="primary"
                  className="big-btn"
                  onClick={() => this.saveUserPhoto()}
                >
                  Yadda saxla
                  {this.state.loading ? (
                    <Spin size="small" className="b-spinner" />
                  ) : null}
                </Button>
              ) : null}
            </div>
          ) : (
            <Button
              className="profile-left edit-line"
              onClick={() => this.setModal1Visible(true)}
              type="primary"
            >
              Profilə düzəliş et
            </Button>
          )
        ) : null}
        {this.state.ownProfile &&
        this.props.profile &&
        !this.props.profile.author ? (
          <Button
            onClick={() => this.setModal2Visible(true)}
            type="primary"
            className="profile-right edit-line-author"
          >
            Saytda sualım var
          </Button>
        ) : null}
        <Modal
          title="Profilə düzəliş et"
          style={{ top: 20 }}
          visible={this.state.modal1Visible}
          // onOk={() => this.setModal1Visible(false)}
          onCancel={() => this.setModal1Visible(false)}
          footer={null}
        >
          <Tabs defaultActiveKey="1">
            <TabPane tab="Əsas məlumatlar" key="1">
              <Form onSubmit={this.handleSubmit}>
                <Row gutter={20}>
                  <Col span={24}>
                    <Form.Item>
                      {getFieldDecorator("name", {
                        validateTrigger: "onBlur",
                        initialValue: this.props.profile
                          ? this.props.profile.name
                          : "",
                        rules: [
                          {
                            required: true,
                            message: "Adınızı daxil edin",
                            whitespace: true,
                          },
                          {
                            min: 2,
                            message: "Ad minimum 2 simvoldan ibarət olmalıdır",
                          },
                        ],
                      })(<Input placeholder="Adınız" />)}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      {getFieldDecorator("surname", {
                        validateTrigger: "onBlur",
                        initialValue: this.props.profile
                          ? this.props.profile.surname
                          : "",
                        rules: [
                          {
                            required: true,
                            message: "Soyadınızı daxil edin",
                            whitespace: true,
                          },
                          {
                            min: 2,
                            message:
                              "Soyad minimum 2 simvoldan ibarət olmalıdır",
                          },
                        ],
                      })(<Input placeholder="Soyadınız" />)}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      {getFieldDecorator("username", {
                        validateTrigger: "onBlur",
                        initialValue: this.props.profile
                          ? this.props.profile.username
                          : "",
                        rules: [
                          {
                            required: true,
                            message: "İstifadəçi adınızı daxil edin",
                            whitespace: true,
                          },
                          {
                            min: 5,
                            message: "Minimum 5 simvol olmalıdır",
                          },
                          {
                            validator: this.checkForWhitespace,
                            message: "İstifadəçi adında boşluq ola bilməz",
                          },
                        ],
                      })(<Input placeholder="İstifadəçi adınız" />)}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item style={{ display: "none" }}>
                      {getFieldDecorator("email", {
                        validateTrigger: "onBlur",
                        initialValue: this.props.profile
                          ? this.props.profile.email
                          : "",
                        rules: [
                          {
                            type: "email",
                            message: "Düzgün elektron poçt daxil edin",
                          },
                          {
                            required: true,
                            message: "Elektron poçtunuzu daxil edin",
                          },
                        ],
                      })(<Input placeholder="Elektron poçt" />)}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      {getFieldDecorator("phone", {
                        validateTrigger: "onBlur",
                        initialValue:
                          this.props.profile && this.props.profile.phone
                            ? this.props.profile.phone.substring(5)
                            : "",
                        rules: [{ required: false }],
                      })(
                        <Input
                          type="number"
                          addonBefore={prefixSelector}
                          style={{ width: "100%" }}
                          placeholder="Əlaqə nömrəniz"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="b-100"
                      >
                        Məlumatlarımı dəyiş
                        {this.state.loading ? (
                          <Spin size="small" className="b-spinner" />
                        ) : null}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </TabPane>
            <TabPane tab="Şifrə" key="2">
              <Form onSubmit={this.handleSubmit2}>
                <Row gutter={20}>
                  <Col span={24}>
                    <Form.Item label="Mövcud şifrəniz" hasFeedback>
                      {getFieldDecorator("current", {
                        validateTrigger: "onBlur",
                        rules: [
                          {
                            required: true,
                            message: "Şifrənizi daxil edin.",
                          },
                        ],
                      })(<Input.Password />)}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Yeni şifrəniz" hasFeedback>
                      {getFieldDecorator("password", {
                        validateTrigger: "onBlur",
                        rules: [
                          {
                            required: true,
                            message: "Şifrənizi təyin edin.",
                          },
                          {
                            min: 6,
                            message: "Şifrə minimal 6 simvol olmalıdır",
                          },
                          {
                            validator: this.validateToNextPassword,
                          },
                        ],
                      })(<Input.Password />)}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Yeni şifrənizi təsdiqləyin" hasFeedback>
                      {getFieldDecorator("confirm", {
                        validateTrigger: "onBlur",
                        rules: [
                          {
                            required: true,
                            message: "Şifrənizi təsdiqləyin!",
                          },
                          {
                            validator: this.compareToFirstPassword,
                            message: "Daxil etdiyiniz şifrələr eyni deyil",
                          },
                        ],
                      })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="b-100"
                      >
                        Şifrəmi dəyiş
                        {this.state.loading ? (
                          <Spin size="small" className="b-spinner" />
                        ) : null}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </TabPane>
          </Tabs>
        </Modal>
        <Modal
          title="Müəllifi seç"
          style={{ top: 20 }}
          visible={this.state.modal2Visible}
          onOk={() => this.linkAuthor()}
          onCancel={() => this.setModal2Visible(false)}
          cancelText={"Ləğv et"}
          okText={"Təsdiqlə"}
        >
          <Select
            showSearch
            value={this.state.authorValue}
            placeholder="Müəllifi axtarın"
            style={{ width: "100%" }}
            defaultActiveFirstOption={false}
            showArrow={true}
            filterOption={false}
            onSearch={this.handleSearch}
            onChange={this.handleChange}
            notFoundContent={null}
          >
            {this.state.authors.map((a) => {
              return (
                <Option value={a.id} key={a.id}>
                  {a.fullname}
                </Option>
              );
            })}
            {/* {options} */}
          </Select>
        </Modal>
      </div>
    ) : (
      <Result
        style={{ height: "520px" }}
        status="404"
        title="404"
        subTitle="Axtardığınız səhifə mövcud deyil"
        extra={
          <Button type="primary">
            <Link to="/">Əsas səhifəyə qayıt</Link>
          </Button>
        }
      />
    );
  }
}

const mapStateToProps = ({ user, profile }) => {
  return {
    user,
    profile,
  };
};

const WrappedProfileForm = Form.create({ name: "update" })(Profile);

export default connect(mapStateToProps, {
  loadingOn,
  loadingOff,
  setUserPhoto,
  getProfile,
  setProfile,
})(WrappedProfileForm);

import { LogoutOutlined } from '@ant-design/icons'
import { Button, Drawer, Icon } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import siteUrl from './../../../const/const'
import history from './../../../const/history'
import { logOut } from './../../../redux/actions'
import './style/miniheader.css'

class Header extends React.Component {
  state = {
    drawerVisible: false,
    packageVisible: false,
  }

  toggleDrawer = (val) => {
    this.setState({
      drawerVisible: val,
    })
  }

  logOut = () => {
    localStorage.removeItem('access_token')
    history.push('/')
    this.props.logOut()
  }

  showModal = () => {}

  render() {
    let clase = 'menu b-100 pv15 fixedMenu flex'
    let activereplacer = 'header-replacer active'
    return (
      <div id="m-Header">
        <div className={activereplacer}></div>
        <div className={clase}>
          <div className="b-container page side">
            <div className="userBtns fr call-btns">
              {/* <a
                href="https://forms.gle/58q6EkEsweieFuiF8"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button type="primary">Bizə qoşul!</Button>
              </a> */}
              {/* <a href="https://forms.gle/nQ2ApPQyqGvJFDFK9"> */}
              {/* <Button type="primary">Marafon dəyərləndirmə anketi</Button> */}
              {/* </a> */}
              <Link to="/order">
                <Button type="primary">Oyun sifariş et</Button>
              </Link>
            </div>
            {!this.props.user.isLoggedIn ? (
              <>
                <div className="userBtns fr">
                  {/* <Link to='/register'>
                    <Button type='primary'>
                      <UserAddOutlined />
                    </Button>
                  </Link>
                  <Link to='/login'>
                    <Button type='primary'>
                      <LoginOutlined />
                    </Button>
                  </Link> */}
                </div>
              </>
            ) : (
              <div className="fr">
                <Link to="/profile">
                  <img
                    className="user-avatar"
                    src={
                      this.props.user.data.photo
                        ? this.props.user.data.photo
                        : `${siteUrl}images/avatar.jpg`
                    }
                    alt="user-avatar"
                  />
                </Link>
                <div className="logOut" onClick={this.logOut}>
                  <LogoutOutlined />
                </div>
              </div>
            )}
            <ul className="fl web">
              <li>
                <Link to="/">Əsas səhifə</Link>
              </li>
              <li>
                <Link to="/search">Sual axtar</Link>
              </li>
              <li>
                <Link to="/authors">Müəlliflər</Link>
              </li>
              {/* <li>
                <Link to='/turnirler'>Turnirlər</Link>
              </li> */}
            </ul>
            <ul className="fl mobile">
              <Button type="primary" onClick={() => this.toggleDrawer(true)}>
                <Icon type="menu-unfold" />
              </Button>
            </ul>
            <div className="drawer mobile">
              <Drawer
                title="3sual.az"
                placement={'left'}
                closable={false}
                onClose={() => this.toggleDrawer(false)}
                visible={this.state.drawerVisible}
              >
                <li>
                  <Link to="/">Əsas səhifə</Link>
                </li>
                <li>
                  <Link to="/search">Sual axtar</Link>
                </li>
                <li>
                  <Link to="/authors">Müəlliflər</Link>
                </li>
                {/* <li>
                  <Link to='/turnirler'>Turnirlər</Link>
                </li> */}
                <li>
                  <Link to="/contact">Əlaqə</Link>
                </li>
              </Drawer>
            </div>
          </div>
        </div>
        <div className="headerLayer"></div>
      </div>
    )
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user,
  }
}

export default connect(mapStateToProps, { logOut })(Header)

const t1 = {
  title: 'Yay liqası',
  desc: 'Fərdi oyun üzrə çempionat',
  slug: null,
  link: 'https://www.facebook.com/groups/3261988784120092',
  info: [
    {
      '№': '1',
      İştirakçı: 'Səyyar Qurbanov',
      'I TUR': '9',
      'II TUR': '13',
      'III TUR': '14.4',
      'IV TUR': '13',
      'V TUR': '17',
      'VI TUR': '20',
      Ümumi: '86.4',
      '': 'İlk 16-lıq',
    },
    {
      '№': '2',
      İştirakçı: 'Heydər Həmzəyev',
      'I TUR': '9',
      'II TUR': '7',
      'III TUR': '15',
      'IV TUR': '20',
      'V TUR': '7',
      'VI TUR': '',
      Ümumi: '58',
      '': 'Tur müəllifi',
    },
    {
      '№': '3',
      İştirakçı: 'Raqib İsmayılov',
      'I TUR': '20',
      'II TUR': '20',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '3',
      'VI TUR': '14.3',
      Ümumi: '57.3',
      '': '16-lıqdan kənar',
    },
    {
      '№': '4',
      İştirakçı: 'Şamxal Cəbiyev',
      'I TUR': '3',
      'II TUR': '11',
      'III TUR': '20',
      'IV TUR': '8.8',
      'V TUR': '3',
      'VI TUR': '7',
      Ümumi: '52.8',
      '': '',
    },
    {
      '№': '5',
      İştirakçı: 'Elgün Abdurahmanov ',
      'I TUR': '7',
      'II TUR': '5',
      'III TUR': '5',
      'IV TUR': '7',
      'V TUR': '20',
      'VI TUR': '5',
      Ümumi: '49',
      '': '',
    },
    {
      '№': '6',
      İştirakçı: 'Nurlan Abdurahmanov ',
      'I TUR': '7',
      'II TUR': '17',
      'III TUR': '9',
      'IV TUR': '11',
      'V TUR': '1',
      'VI TUR': '3',
      Ümumi: '48',
      '': '',
    },
    {
      '№': '7',
      İştirakçı: 'Əşrəf Paşabəyli',
      'I TUR': '5',
      'II TUR': '',
      'III TUR': '11',
      'IV TUR': '17',
      'V TUR': '15',
      'VI TUR': '',
      Ümumi: '48',
      '': '',
    },
    {
      '№': '8',
      İştirakçı: 'Əhməd Əliyev',
      'I TUR': '1',
      'II TUR': '7',
      'III TUR': '11',
      'IV TUR': '1',
      'V TUR': '11',
      'VI TUR': '11',
      Ümumi: '42',
      '': '',
    },
    {
      '№': '9',
      İştirakçı: 'Emin Qasımov',
      'I TUR': '7',
      'II TUR': '3',
      'III TUR': '7',
      'IV TUR': '7',
      'V TUR': '7',
      'VI TUR': '11',
      Ümumi: '42',
      '': '',
    },
    {
      '№': '10',
      İştirakçı: 'Namiq Quluzadə',
      'I TUR': '3',
      'II TUR': '',
      'III TUR': '7',
      'IV TUR': '11',
      'V TUR': '',
      'VI TUR': '17',
      Ümumi: '38',
      '': '',
    },
    {
      '№': '11',
      İştirakçı: 'Ramazan İbrahimli',
      'I TUR': '3',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '15',
      'V TUR': '5',
      'VI TUR': '15',
      Ümumi: '38',
      '': '',
    },
    {
      '№': '12',
      İştirakçı: 'Rza Dadaşzadə',
      'I TUR': '15',
      'II TUR': '',
      'III TUR': '9',
      'IV TUR': '1',
      'V TUR': '3',
      'VI TUR': '7',
      Ümumi: '35',
      '': '',
    },
    {
      '№': '13',
      İştirakçı: 'Orxan Cəlilov',
      'I TUR': '',
      'II TUR': '9',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '11',
      'VI TUR': '13',
      Ümumi: '33',
      '': '',
    },
    {
      '№': '14',
      İştirakçı: 'Rahib İsmayılov',
      'I TUR': '',
      'II TUR': '11',
      'III TUR': '1',
      'IV TUR': '9',
      'V TUR': '3',
      'VI TUR': '9',
      Ümumi: '33',
      '': '',
    },
    {
      '№': '15',
      İştirakçı: 'Səbuhi Baxşıyev',
      'I TUR': '5',
      'II TUR': '9',
      'III TUR': '17',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '31',
      '': '',
    },
    {
      '№': '16',
      İştirakçı: 'Şahmurad Məmmədov',
      'I TUR': '3',
      'II TUR': '5',
      'III TUR': '',
      'IV TUR': '1',
      'V TUR': '13',
      'VI TUR': '7',
      Ümumi: '29',
      '': '',
    },
    {
      '№': '17',
      İştirakçı: 'Nəriman Vəliyev',
      'I TUR': '11',
      'II TUR': '',
      'III TUR': '13',
      'IV TUR': '3',
      'V TUR': '1',
      'VI TUR': '',
      Ümumi: '28',
      '': '',
    },
    {
      '№': '18',
      İştirakçı: 'Camal Məmmədov',
      'I TUR': '3',
      'II TUR': '15',
      'III TUR': '',
      'IV TUR': '3',
      'V TUR': '5',
      'VI TUR': '',
      Ümumi: '26',
      '': '',
    },
    {
      '№': '19',
      İştirakçı: 'Rüstəm Babayev',
      'I TUR': '5',
      'II TUR': '5',
      'III TUR': '1',
      'IV TUR': '',
      'V TUR': '9',
      'VI TUR': '5',
      Ümumi: '25',
      '': '',
    },
    {
      '№': '20',
      İştirakçı: 'Səməd Səmədov',
      'I TUR': '13',
      'II TUR': '1',
      'III TUR': '3',
      'IV TUR': '3',
      'V TUR': '5',
      'VI TUR': '',
      Ümumi: '25',
      '': '',
    },
    {
      '№': '21',
      İştirakçı: 'Qorxmaz Aydınlı',
      'I TUR': '17',
      'II TUR': '3',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '20',
      '': '',
    },
    {
      '№': '22',
      İştirakçı: 'Həsən Abbaslı',
      'I TUR': '',
      'II TUR': '5',
      'III TUR': '5',
      'IV TUR': '3',
      'V TUR': '7',
      'VI TUR': '',
      Ümumi: '20',
      '': '',
    },
    {
      '№': '23',
      İştirakçı: 'Elvin Əliyev',
      'I TUR': '1',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '7',
      'V TUR': '7',
      'VI TUR': '5',
      Ümumi: '20',
      '': '',
    },
    {
      '№': '24',
      İştirakçı: 'Fazil Babayev',
      'I TUR': '1',
      'II TUR': '',
      'III TUR': '3',
      'IV TUR': '9',
      'V TUR': '',
      'VI TUR': '5',
      Ümumi: '18',
      '': '',
    },
    {
      '№': '25',
      İştirakçı: 'Nail Qasımzadə',
      'I TUR': '',
      'II TUR': '3',
      'III TUR': '7',
      'IV TUR': '5',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '15',
      '': '',
    },
    {
      '№': '26',
      İştirakçı: 'Abdurrahman Vaqifli',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '1',
      'IV TUR': '3',
      'V TUR': '5',
      'VI TUR': '5',
      Ümumi: '14',
      '': '',
    },
    {
      '№': '27',
      İştirakçı: 'Rəşad Şahverənov',
      'I TUR': '7',
      'II TUR': '1',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '3',
      'VI TUR': '3',
      Ümumi: '14',
      '': '',
    },
    {
      '№': '28',
      İştirakçı: 'Əli Əliyev',
      'I TUR': '7',
      'II TUR': '',
      'III TUR': '7',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '14',
      '': '',
    },
    {
      '№': '29',
      İştirakçı: 'Rəsul Əlizadə',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '3',
      'V TUR': '9',
      'VI TUR': '',
      Ümumi: '12',
      '': '',
    },
    {
      '№': '30',
      İştirakçı: 'Akif Məhərrəmov',
      'I TUR': '5',
      'II TUR': '7',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '12',
      '': '',
    },
    {
      '№': '31',
      İştirakçı: 'Kənan Dadaşzadə',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '3',
      'IV TUR': '',
      'V TUR': '5',
      'VI TUR': '3',
      Ümumi: '11',
      '': '',
    },
    {
      '№': '32',
      İştirakçı: 'Aytəkin Əsədzadə',
      'I TUR': '11',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '11',
      '': '',
    },
    {
      '№': '33',
      İştirakçı: 'Elmar Yusifov',
      'I TUR': '1',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '1',
      'V TUR': '1',
      'VI TUR': '7',
      Ümumi: '10',
      '': '',
    },
    {
      '№': '34',
      İştirakçı: 'Uluxan Uluxanlı',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '7',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '3',
      Ümumi: '10',
      '': '',
    },
    {
      '№': '35',
      İştirakçı: 'Fərid Azakov',
      'I TUR': '1',
      'II TUR': '3',
      'III TUR': '1',
      'IV TUR': '5',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '10',
      '': '',
    },
    {
      '№': '36',
      İştirakçı: 'Qulu Əlisoy',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '9',
      Ümumi: '9',
      '': '',
    },
    {
      '№': '37',
      İştirakçı: 'Soltan Əzimov',
      'I TUR': '5',
      'II TUR': '1',
      'III TUR': '1',
      'IV TUR': '1',
      'V TUR': '1',
      'VI TUR': '',
      Ümumi: '9',
      '': '',
    },
    {
      '№': '38',
      İştirakçı: 'Heydər Abdullayev',
      'I TUR': '',
      'II TUR': '7',
      'III TUR': '1',
      'IV TUR': '1',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '9',
      '': '',
    },
    {
      '№': '39',
      İştirakçı: 'Murad İskəndər',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '5',
      'V TUR': '',
      'VI TUR': '3',
      Ümumi: '8',
      '': '',
    },
    {
      '№': '40',
      İştirakçı: 'Adil Əsgərov',
      'I TUR': '',
      'II TUR': '5',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '3',
      'VI TUR': '',
      Ümumi: '8',
      '': '',
    },
    {
      '№': '41',
      İştirakçı: 'Nurlan Əhmədov',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '5',
      'IV TUR': '3',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '8',
      '': '',
    },
    {
      '№': '42',
      İştirakçı: 'Osman Salmanlı',
      'I TUR': '1',
      'II TUR': '3',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '3',
      'VI TUR': '',
      Ümumi: '7',
      '': '',
    },
    {
      '№': '43',
      İştirakçı: 'Yusif Yaquboğlu',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '7',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '7',
      '': '',
    },
    {
      '№': '44',
      İştirakçı: 'Rəsul Balayev',
      'I TUR': '',
      'II TUR': '7',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '7',
      '': '',
    },
    {
      '№': '45',
      İştirakçı: 'Adil Nəhmətli',
      'I TUR': '3',
      'II TUR': '',
      'III TUR': '1',
      'IV TUR': '1.7',
      'V TUR': '1',
      'VI TUR': '',
      Ümumi: '6.7',
      '': '',
    },
    {
      '№': '46',
      İştirakçı: 'Qədir Səfərli',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '3',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '3',
      Ümumi: '6',
      '': '',
    },
    {
      '№': '47',
      İştirakçı: 'Ramal Ağazadə',
      'I TUR': '',
      'II TUR': '3',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '3',
      'VI TUR': '',
      Ümumi: '6',
      '': '',
    },
    {
      '№': '48',
      İştirakçı: 'Sabir Rüstəmov',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '5',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '5',
      '': '',
    },
    {
      '№': '49',
      İştirakçı: 'Şükran İbrahimli',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '5',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '5',
      '': '',
    },
    {
      '№': '50',
      İştirakçı: 'Xəzər Əhmədov',
      'I TUR': '5',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '5',
      '': '',
    },
    {
      '№': '51',
      İştirakçı: 'Manisa Rəhimli',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '3',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '1',
      Ümumi: '4',
      '': '',
    },
    {
      '№': '52',
      İştirakçı: 'Bəhruz Aydınlı',
      'I TUR': '3',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '1',
      'VI TUR': '',
      Ümumi: '4',
      '': '',
    },
    {
      '№': '53',
      İştirakçı: 'Emin Fətəliyev',
      'I TUR': '',
      'II TUR': '1',
      'III TUR': '3',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '4',
      '': '',
    },
    {
      '№': '54',
      İştirakçı: 'Gülnur Əkbərova',
      'I TUR': '1',
      'II TUR': '3',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '4',
      '': '',
    },
    {
      '№': '55',
      İştirakçı: 'Nilufər Tağıyeva',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '3',
      Ümumi: '3',
      '': '',
    },
    {
      '№': '56',
      İştirakçı: 'Aydın Abbaslı',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '3',
      Ümumi: '3',
      '': '',
    },
    {
      '№': '57',
      İştirakçı: 'Murad Axundov',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '3',
      'VI TUR': '',
      Ümumi: '3',
      '': '',
    },
    {
      '№': '58',
      İştirakçı: 'Taleh Məmmədli',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '3',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '3',
      '': '',
    },
    {
      '№': '59',
      İştirakçı: 'Sahib Həsənzadə',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '3',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '3',
      '': '',
    },
    {
      '№': '60',
      İştirakçı: 'Elnur Qasımzadə',
      'I TUR': '3',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '3',
      '': '',
    },
    {
      '№': '61',
      İştirakçı: 'Tural Hüseynzadə',
      'I TUR': '3',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '3',
      '': '',
    },
    {
      '№': '62',
      İştirakçı: 'Dilarə Rəfi',
      'I TUR': '',
      'II TUR': '3',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '3',
      '': '',
    },
    {
      '№': '63',
      İştirakçı: 'Dadaş Dadaşov',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '3',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '3',
      '': '',
    },
    {
      '№': '64',
      İştirakçı: 'Emin Məmmədov',
      'I TUR': '1',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '1',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '2',
      '': '',
    },
    {
      '№': '65',
      İştirakçı: 'İsmayıl Adıgözəlov',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '1',
      Ümumi: '1',
      '': '',
    },
    {
      '№': '66',
      İştirakçı: 'Gülnar Rəhimli',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '1',
      Ümumi: '1',
      '': '',
    },
    {
      '№': '67',
      İştirakçı: 'Turqut Nəhmətli',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '1',
      'VI TUR': '',
      Ümumi: '1',
      '': '',
    },
    {
      '№': '68',
      İştirakçı: 'Anar Vəliyev',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '1',
      'VI TUR': '',
      Ümumi: '1',
      '': '',
    },
    {
      '№': '69',
      İştirakçı: 'Elşən Almaslı',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '1',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '1',
      '': '',
    },
    {
      '№': '70',
      İştirakçı: 'Xalid İsmayıllı',
      'I TUR': '',
      'II TUR': '',
      'III TUR': '1',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '1',
      '': '',
    },
    {
      '№': '71',
      İştirakçı: 'Çingiz Məmmədov',
      'I TUR': '1',
      'II TUR': '',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '1',
      '': '',
    },
    {
      '№': '72',
      İştirakçı: 'Seyidhəşim Həşimli',
      'I TUR': '',
      'II TUR': '1',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '1',
      '': '',
    },
    {
      '№': '73',
      İştirakçı: 'Ruhid Cavadlı',
      'I TUR': '',
      'II TUR': '1',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '1',
      '': '',
    },
    {
      '№': '74',
      İştirakçı: 'Babək Qasımzadə',
      'I TUR': '',
      'II TUR': '1',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '1',
      '': '',
    },
    {
      '№': '75',
      İştirakçı: 'Samir Həsənzadə',
      'I TUR': '',
      'II TUR': '1',
      'III TUR': '',
      'IV TUR': '',
      'V TUR': '',
      'VI TUR': '',
      Ümumi: '1',
      '': '',
    },
  ].filter((item, index) => index < 32),
};
export default t1;

import React from "react";
import Quiz from "./Single/Quiz";

class Quizzes extends React.Component {
  state = {
    opens: [],
  };

  allOpen() {
    let resp = true;
    if (!this.props.phase) {
      return false;
    }
    this.props.phase.themes.forEach((q) => {
      for (let i = 0; i < 5; i++) {
        if (!this.state.opens.includes(`${q.id}-${i}`)) {
          resp = false;
        }
      }
    });
    return resp;
  }

  toggleAll = () => {
    const show = this.allOpen();
    if (!show) {
      let obj = [...this.state.opens];
      this.props.phase.themes.forEach((q) => {
        obj.push(`${q.id}-0`);
        obj.push(`${q.id}-1`);
        obj.push(`${q.id}-2`);
        obj.push(`${q.id}-3`);
        obj.push(`${q.id}-4`);
      });
      this.setState({
        opens: obj,
      });
    } else {
      this.setState({
        opens: [],
      });
    }
  };

  toggleOpen = (theme, question) => {
    let ope = [...this.state.opens];
    if (this.state.opens.includes(`${theme}-${question}`)) {
      this.setState({
        opens: ope.filter((o) => o !== `${theme}-${question}`),
      });
    } else {
      ope.push(`${theme}-${question}`);
      this.setState({
        opens: ope,
      });
    }
  };

  toggleAnswer(theme, question) {
    console.log("Theme is: " + theme);
    console.log("Question is: " + question);
  }

  renderQuiz(raund) {
    return this.props.phase.themes.length ? (
      <div className="raund">
        {this.props.phase.themes.map((co) => (
          <Quiz
            key={co.id}
            opens={this.state.opens}
            theme={co}
            toggleOpen={this.toggleOpen}
          />
        ))}
      </div>
    ) : null;
  }

  render() {
    return (
      <>
        <div className="toggleButton" onClick={() => this.toggleAll()}>
          {this.allOpen() ? "Bütün cavabları gizlət" : "Bütün cavabları göstər"}
        </div>
        <div>{this.renderQuiz(null)}</div>
      </>
    );
  }
}

export default Quizzes;

import React from "react";
import { Form, Icon, Input, Button, notification, Spin } from "antd";
import { Link } from "react-router-dom";
import "./style/Forgot.css";
import sualtap from "../../../const/axios";
import { FrownOutlined, SmileOutlined } from "@ant-design/icons";
import history from "./../../../const/history";

class Forgot extends React.Component {
  state = {
    loading: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        await sualtap
          .post("auth/forgot", JSON.stringify({ email: values.email }))
          .then((res) => {
            if (res.data.success) {
              notification.info({
                message: "Əməliyyat uğurlu oldu",
                description: res.data.message,
                placement: "bottomRight",
                icon: <SmileOutlined />,
              });
              history.push("/");
            } else {
              notification.info({
                message: "Əməliyyat uğursuz oldu",
                description: res.data.message,
                placement: "bottomRight",
                icon: <FrownOutlined />,
              });
            }
            this.setState({
              loading: false,
            });
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="b-container page">
        <div className="loginForm">
          <h3 className="page-name">Şifrəmi yenilə</h3>
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator("email", {
                validateTrigger: "onBlur",
                rules: [
                  {
                    type: "email",
                    message: "Düzgün elektron poçt daxil edin",
                  },
                  {
                    required: true,
                    message: "Elektron poçtunuzu daxil edin",
                  },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Elektron poçt"
                />
              )}
            </Form.Item>
            <Form.Item className="forgot-form-row">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ width: "50%" }}
              >
                Email göndər
                {this.state.loading ? (
                  <Spin className="b-spinner" size="small" />
                ) : null}
              </Button>
              <span style={{ marginTop: "-5px" }}>
                Və ya <Link to="/Login" className="fn">daxil ol!</Link>
              </span>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

const WrappedNormalForgotForm = Form.create({ name: "normal_forgot" })(Forgot);

export default WrappedNormalForgotForm;

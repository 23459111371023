import React from "react";
import { Tag } from "antd";
import { Link } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";

const Author = (props) => {
  return (
    <div className="author">
      {props.author.user != null ? (
        <Link
          className="author-name"
          style={{ color: "black", fontWeight: "bold" }}
          to={`/profile/${props.author.user.username}`}
        >
          <p className="fl b-100">
            {props.author.fullname} <RightOutlined className="profile-icon" />
          </p>
        </Link>
      ) : (
        <p className="fl b-100">{props.author.fullname}</p>
      )}
      <div className="tag-line">
        {props.author.editors ? (
          <Tag color="#2db7f5">
            <Link to={`/editor/packages/${props.author.id}`}>
              {props.author.editors} {"redaktə"}
            </Link>
          </Tag>
        ) : (
          <Tag color="#2db7f5">0 redaktə</Tag>
        )}
        {props.author.questions > 0 || props.author.themes > 0 ? (
          <>
            {props.author.questions === 0 ? (
              <Tag color="#2db7f5">
                {props.author.questions} {"sual"}
              </Tag>
            ) : (
              <Link to={`/author/questions/${props.author.id}`}>
                <Tag color="#2db7f5">
                  {props.author.questions} {"sual"}
                </Tag>
              </Link>
            )}
            {props.author.themes === 0 ? (
              <Tag color="#2db7f5">
                {props.author.themes} {"mövzu"}
              </Tag>
            ) : (
              <Link to={`/author/themes/${props.author.id}`}>
                <Tag color="#2db7f5">
                  {props.author.themes} {"mövzu"}
                </Tag>
              </Link>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Author;

import React from 'react';
import {connect} from 'react-redux';
import {loadingOn, loadingOff} from './../../../../redux/actions/index';
import './d.css';

class DersEvi extends React.Component {
  componentDidMount = async () => {
    this.props.loadingOn();
    setTimeout(() => {
      this.props.loadingOff();
    }, 3000);
  };

  render() {
    return (
      <div className='b-container page'>
        <iframe
          className='register-event'
          width='100%'
          height='500'
          title='qeydiyyat'
          src='https://forms.gle/Besp5rtJsfPY2XdN9'
        />
      </div>
    );
  }
}

export default connect(null, {loadingOn, loadingOff})(DersEvi);

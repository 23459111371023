import React from 'react'
import { Input, Button, message } from 'antd'
import './style/style.css'
import 'react-toastify/dist/ReactToastify.css'
import sualtap from '../../../const/axios'
import { connect } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
const { TextArea } = Input

class Order extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState() {
    return {
      name: '',
      surname: '1',
      email: '',
      phone: '',
      isHandled: false,
      message: '',
      topic: 'Package order ' + new Date(),
    }
  }

  componentDidMount() {
    if (this.props.user.isLoggedIn) {
      this.setState({
        name: this.props.user.data.name,
        surname: this.props.user.data.surname,
        email: this.props.user.data.email,
        phone: this.props.user.data.phone,
      })
    }
  }

  handleTextChange = ({ target: { value, name } }) => {
    const newState = { ...this.state }
    newState[name] = value
    this.setState(newState)
  }

  validateContact = () => {
    let arr = []
    !this.state.name.length ? arr.push('Adınızı') : arr.push('')
    !this.state.email.length && !this.state.phone.length < 10
      ? arr.push('Emailinizi və ya əlaqə nömrənizi')
      : arr.push('')
    this.state.message.length < 2
      ? arr.push('Planladığınız yarış haqqında qısa məlumatı')
      : arr.push('')
    arr
      .filter((a) => a !== '')
      .forEach((a) => {
        message.warning(`${a} daxil edin`)
      })
    console.log(arr)
    return arr.filter((a) => a !== '').length < 1
  }
  submitContact = () => {
    if (this.validateContact()) {
      sualtap.post('contacts', { ...this.state }).then((res) => {
        toast(
          'Bizə müraciət etdiyiniz üçün minnətdarıq. Ən qısa zamanda sizinlə əlaqə saxlayacağıq!'
        )
        this.setState(this.getInitialState())
      })
    }
  }

  render() {
    return (
      <div className="b-contact">
        <ToastContainer />
        <div className="overlay"></div>
        <div className="contact-form">
          <div className="two-form order-form">
            <h1>Bİzə yazın</h1>
            <p>
              Peşəkar komandamız sizin üçün oyun hazırlamağa hazırdır!
              Arzuladığınız tədbir barədə bizə yazın, sizinlə əlaqə yaradaq ;)
            </p>
          </div>
          <div className="two-form">
            <Input
              size="large"
              placeholder="Ad və soyadınız"
              value={this.state.name}
              name="name"
              onChange={this.handleTextChange}
              disabled={this.props.user.isLoggedIn}
            />
          </div>
          <div className="two-form">
            <Input
              size="large"
              placeholder="Email"
              value={this.state.email}
              name="email"
              onChange={this.handleTextChange}
              disabled={this.props.user.isLoggedIn}
            />
          </div>
          <div className="two-form">
            <Input
              size="large"
              placeholder="Əlaqə nömrəsi"
              value={this.state.phone}
              name="phone"
              onChange={this.handleTextChange}
              disabled={this.props.user.isLoggedIn}
            />
          </div>
          <div className="two-form">
            <TextArea
              rows={6}
              placeholder="Planladığınız oyun haqqında qısa məlumat"
              value={this.state.message}
              name="message"
              onChange={this.handleTextChange}
            ></TextArea>
          </div>
          <Button
            onClick={() => this.submitContact()}
            type="primary"
            className="float-r"
            size="large"
          >
            Göndər
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user,
  }
}

export default connect(mapStateToProps, null)(Order)

import { Button, Collapse, Form, Input, Radio, Tag } from 'antd'
import copy from 'copy-to-clipboard'
import React, { useEffect, useMemo, useState } from 'react'

const { Panel } = Collapse

const themeInitial = {
  players: [0, 0, 0, 0],
  scores: [],
}

const PlayGame = ({ isAdmin }) => {
  const [selected, setSelected] = useState([])
  const [teams, setTeams] = useState([])
  const [gameData, setGameData] = useState({
    teams: [],
    themes: [themeInitial],
  })
  const [settings, setSettings] = useState({
    theme: 0,
    question: 0,
    selection: true,
  })
  const [values, setValues] = useState({ players: '', teamName: '' })

  const allPlayers = useMemo(() => {
    const playerMap = {}
    gameData.teams.forEach((team) => {
      team.players.forEach((player, index) => {
        playerMap[player.id] = {
          name: player.name,
          team: team.name,
          index,
          id: player.id,
        }
      })
    })
    return playerMap
  }, [gameData])

  useEffect(() => {
    setTeams(JSON.parse(localStorage.getItem('3sual-teams-data') ?? '[]'))
    // [{"name":"Berserk","score":0,"playing":0,"players":[{"scores":[],"name":"A","id":1680287553010},{"scores":[],"name":"B","id":1680287553010},{"scores":[],"name":"C","id":1680287553009},{"scores":[],"name":"D","id":1680287553008}]},{"name":"JM","score":0,"playing":0,"players":[{"scores":[],"name":"E","id":1680287560225},{"scores":[],"name":"F","id":1680287560224},{"scores":[],"name":"G","id":1680287560223},{"scores":[],"name":"H","id":1680287560222}]}]
    setGameData(
      JSON.parse(
        localStorage.getItem('3sual-game-data') ??
          JSON.stringify({ teams: [], themes: [themeInitial] })
      )
    )
    setSettings(
      JSON.parse(
        localStorage.getItem('3sual-current-question-data') ??
          JSON.stringify({ theme: 0, question: 0, selection: true })
      )
    )
    setSelected(
      JSON.parse(localStorage.getItem('3sual-selected-teams-data') ?? '[]')
    )
  }, [])

  useEffect(() => {
    localStorage.setItem('3sual-game-data', JSON.stringify(gameData))
  }, [gameData])

  useEffect(() => {
    localStorage.setItem('3sual-teams-data', JSON.stringify(teams))
  }, [teams])

  useEffect(() => {
    localStorage.setItem('3sual-selected-teams-data', JSON.stringify(selected))
  }, [selected])

  useEffect(() => {
    localStorage.setItem(
      '3sual-current-question-data',
      JSON.stringify(settings)
    )
  }, [settings])

  return isAdmin ? (
    <div className="b-container">
      <div style={{ marginTop: '10px' }}>
        <h3>Komandaları seç</h3>
        <div>
          {teams.map((team, teamIndex) => {
            const isSelected = selected.find((s) => s.name === team.name)
            return (
              <Button
                key={`team-team-${teamIndex}`}
                style={{
                  cursor: 'pointer',
                  backgroundColor: isSelected ? 'green' : 'gray',
                  color: 'white',
                }}
                onClick={() => {
                  setSelected((prev) => {
                    return isSelected
                      ? [...prev].filter((item) => item.name !== team.name)
                      : [...prev, team]
                  })
                }}
              >
                {team.name}
              </Button>
            )
          })}
        </div>
      </div>
      <Button
        style={{
          marginTop: '5px',
          marginBottom: '15px',
          cursor: 'pointer',
        }}
        onClick={() => {
          copy(JSON.stringify(selected), '')
          alert(
            'Bu komandaların məlumatları kopyalandı: ' +
              selected.map((s) => s.name).join(', ')
          )
        }}
      >
        Seçilmiş komanda məlumatlarını götür
      </Button>
      <hr />
      <Form
        onSubmit={(e) => {
          e.preventDefault()
          const pD = {
            name: e.target.teamName.value.trim(),
            score: 0,
            playing: '',
            players: e.target.players.value.split(',').map((item) => ({
              scores: [],
              name: item.trim(),
              id:
                new Date().getTime() +
                Math.floor(Math.random() * (100 - 1 + 1) + 1),
            })),
          }
          setTeams((prev) => {
            const updatedTeams = [...prev]
            updatedTeams.push(pD)
            return updatedTeams
          })
          setValues({ players: '', teamName: '' })
        }}
        style={{ marginBottom: '20px', marginTop: '15px' }}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item name="teamName" label="Komanda adı">
          <Input
            name="teamName"
            size="large"
            value={values.teamName}
            onChange={(e) => {
              const teamName = e.target.value
              setValues((prev) => ({ ...prev, teamName }))
            }}
            placeholder="Komanda adını daxil et"
          />
        </Form.Item>
        <Form.Item name="players" label="Oyunçular">
          <Input
            name="players"
            size="large"
            value={values.players}
            onChange={(e) => {
              const players = e.target.value
              setValues((prev) => ({ ...prev, players }))
            }}
            placeholder="Komanda adını daxil et"
          />
        </Form.Item>
        <div>
          <Button type="primary" htmlType="submit">
            Komandanı əlavə et
          </Button>
        </div>
      </Form>
      <hr />
      <table
        style={{
          width: '100%',
          overflowX: 'scroll',
          marginBlock: '15px',
        }}
      >
        <thead>
          <tr>
            <th>
              <h3>
                <b>Komanda</b>
              </h3>
            </th>
            <th>
              <h3>
                <b>Oyunçular</b>
              </h3>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team, index) => {
            return (
              <tr key={`team-${index}`}>
                <td>
                  <b> {team.name} </b>
                </td>
                <td>{team.players.map((pl) => pl.name).join(', ')}</td>
                <td>
                  <Button
                    type="primary"
                    onClick={() => {
                      setTeams((prev) => {
                        const updated = [...prev]
                        updated.splice(index, 1)
                        return updated
                      })
                      setSelected((prev) => {
                        const updated = [...prev]
                        const indexOf = updated.findIndex(
                          (t) => team.name === t.name
                        )
                        updated.splice(indexOf, 1)
                        return updated
                      })
                    }}
                  >
                    Sil
                  </Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  ) : (
    <>
      <Collapse defaultActiveKey={['2']}>
        <Panel header="Komanda məlumatları" key="1">
          <div className="b-container">
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                setGameData({
                  teams: JSON.parse(e.target.teamList.value),
                  themes: [themeInitial],
                })
                e.target.reset()
              }}
              layout="vertical"
              autoComplete="off"
            >
              <Form.Item name="teamList" label="Komandalar">
                <Input
                  name="teamList"
                  size="large"
                  placeholder="Komanda məlumatlarını daxil et"
                />
              </Form.Item>
              <div style={{ display: 'flex', gap: '5px' }}>
                <Button htmlType="submit">Oyuna başla</Button>
                <Button
                  onClick={() => {
                    setGameData({
                      teams: [],
                      themes: [themeInitial],
                    })
                    setSettings({ theme: 0, question: 0, selection: true })
                  }}
                >
                  Oyunu sıfırla
                </Button>
              </div>
            </Form>
          </div>
        </Panel>
        <Panel header="Oyun paneli" key="2">
          <div className="b-container">
            <h2>
              Mövzu: {settings.theme + 1} | Sual: {settings.question + 1}
            </h2>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '5px',
                flexWrap: 'wrap',
              }}
            >
              <Button
                type="primary"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setGameData((prev) => {
                    const updated = { ...prev }
                    updated.themes.push(themeInitial)
                    updated.teams.forEach((team) => {
                      team.playing = ''
                    })
                    return updated
                  })
                  setSettings((prev) => ({
                    theme: prev.theme + 1,
                    question: 0,
                    selection: true,
                  }))
                }}
              >
                Növbəti mövzuya keç
              </Button>
              <Button
                type="primary"
                style={{ cursor: 'pointer' }}
                disabled={settings.question === 4}
                onClick={() => {
                  setSettings((prev) => ({
                    ...prev,
                    question: prev.question + 1,
                    theme: prev.theme,
                  }))
                }}
              >
                Növbəti suala keç
              </Button>
              <Button
                type="primary"
                style={{ cursor: 'pointer' }}
                disabled={settings.question === 0}
                onClick={() => {
                  setSettings((prev) => ({
                    ...prev,
                    question: prev.question - 1,
                    theme: prev.theme,
                  }))
                }}
              >
                Öncəki suala keç
              </Button>
              {settings.selection && (
                <Button
                  disabled={gameData.teams.some((team) => !team.playing)}
                  type="primary"
                  style={{ marginBottom: '5px' }}
                  onClick={() => {
                    setSettings((prev) => ({ ...prev, selection: false }))
                  }}
                >
                  Oyunçuları yadda saxla
                </Button>
              )}
            </div>
          </div>
          <hr />

          <div className="b-container">
            {gameData.teams.map((team, teamIndex) => (
              <React.Fragment key={`teamIndex-${teamIndex}`}>
                <div>
                  <Radio.Group
                    value={team.playing}
                    onChange={(e) =>
                      setGameData((prev) => {
                        const updated = { ...prev }
                        updated.teams[teamIndex].playing = e.target.value
                        updated.themes[settings.theme].players[teamIndex] =
                          e.target.value.split('-')[0]
                        return updated
                      })
                    }
                  >
                    {team.players.map((player, playerIndex) => {
                      const isPlaying =
                        team.playing === `${player.id}-${player.name}`
                      return (
                        <Radio.Button
                          disabled={!settings.selection && !isPlaying}
                          value={`${player.id}-${player.name}`}
                          key={`player-${playerIndex}`}
                        >
                          {player.name}
                          {isPlaying ? ' [oynayır]' : ''}
                        </Radio.Button>
                      )
                    })}
                  </Radio.Group>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '5px',
                      marginBlock: '5px',
                    }}
                  >
                    <b>
                      {team.name}: {team.score}00
                    </b>
                    <div>
                      <Button
                        disabled={settings.selection}
                        onClick={() => {
                          setGameData((prev) => {
                            const updated = { ...prev }
                            const player =
                              updated.themes[settings.theme].players[teamIndex]
                            const key = `${settings.question}-p-${player}`
                            if (
                              !updated.themes[settings.theme].scores.includes(
                                key
                              )
                            ) {
                              updated.themes[settings.theme].scores.push(key)
                              updated.teams[teamIndex].score +=
                                settings.question + 1
                            }
                            return updated
                          })
                          setSettings((prev) => ({
                            ...prev,
                            question:
                              prev.question < 4
                                ? prev.question + 1
                                : prev.question,
                          }))
                        }}
                      >
                        +
                      </Button>
                      <Button
                        disabled={settings.selection}
                        onClick={() => {
                          setGameData((prev) => {
                            const updated = { ...prev }
                            const player =
                              updated.themes[settings.theme].players[teamIndex]
                            const key = `${settings.question}-n-${player}`
                            if (
                              !updated.themes[settings.theme].scores.includes(
                                key
                              )
                            ) {
                              updated.themes[settings.theme].scores.push(key)
                              updated.teams[teamIndex].score -=
                                settings.question + 1
                            }
                            return updated
                          })
                        }}
                      >
                        -
                      </Button>
                    </div>
                  </div>
                </div>
                <hr />
              </React.Fragment>
            ))}
          </div>
          <div className="b-container">
            <Button
              type="primary"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                copy(JSON.stringify(gameData), '')
                alert('Oyunun nəticələri kopyalandı')
              }}
            >
              Oyunun nəticələrini götür
            </Button>
          </div>
        </Panel>
        <Panel header="Oyun cədvəli" key="3">
          {gameData.themes.map((theme, themeIndex) => {
            const listOfQuestions = []
            for (let i = 0; i < 5; i++) {
              const listOfPlayers = []
              for (let j = 0; j < 4; j++) {
                const isAPlayer = theme.players[j] !== 0
                listOfPlayers.push({
                  name: isAPlayer ? allPlayers[theme.players[j]].name : '',
                  team: isAPlayer ? allPlayers[theme.players[j]].team : '',
                  id: isAPlayer ? allPlayers[theme.players[j]].id : '',
                  index: j,
                  isPositive: isAPlayer
                    ? theme.scores.includes(`${i}-p-${theme.players[j]}`)
                    : false,
                  isNegative: isAPlayer
                    ? theme.scores.includes(`${i}-n-${theme.players[j]}`)
                    : false,
                })
              }
              listOfQuestions.push({
                players: listOfPlayers,
                theme: themeIndex + 1,
                question: i + 1,
              })
            }
            return listOfQuestions.map((question, questionIndex) => (
              <>
                <div key={`question-${questionIndex}`}>
                  <div>
                    Mövzu: {question.theme} | Sual: {question.question}
                  </div>
                  <div>
                    {question.players.map((player, playerIndex) => {
                      return player.name.length ? (
                        <div>
                          <Tag
                            color={
                              player.isPositive
                                ? 'green'
                                : player.isNegative
                                ? 'red'
                                : 'gray'
                            }
                          >
                            {player.name}[{player.team}]
                          </Tag>
                          <Button
                            onClick={() => {
                              setGameData((prev) => {
                                const updated = { ...prev }
                                const team = updated.teams[player.index]
                                if (player.isNegative) {
                                  team.score += 2 * question.question
                                  const indexOf = theme.scores.indexOf(
                                    `${questionIndex}-n-${player.id}`
                                  )
                                  theme.scores[
                                    indexOf
                                  ] = `${questionIndex}-p-${player.id}`
                                } else if (!player.isPositive) {
                                  team.score += question.question
                                  theme.scores.push(
                                    `${questionIndex}-p-${player.id}`
                                  )
                                }
                                return updated
                              })
                            }}
                            size="small"
                          >
                            1
                          </Button>
                          <Button
                            size="small"
                            onClick={() => {
                              setGameData((prev) => {
                                const updated = { ...prev }
                                const team = updated.teams[player.index]
                                if (player.isPositive) {
                                  team.score -= 2 * question.question
                                  const indexOf = theme.scores.indexOf(
                                    `${questionIndex}-p-${player.id}`
                                  )
                                  theme.scores[
                                    indexOf
                                  ] = `${questionIndex}-n-${player.id}`
                                } else if (!player.isNegative) {
                                  team.score -= question.question
                                  theme.scores.push(
                                    `${questionIndex}-n-${player.id}`
                                  )
                                }
                                return updated
                              })
                            }}
                          >
                            -1
                          </Button>
                          <Button
                            size="small"
                            onClick={() => {
                              setGameData((prev) => {
                                const updated = { ...prev }
                                const team = updated.teams[player.index]
                                if (player.isPositive) {
                                  team.score -= question.questions
                                  const indexOf = theme.scores.indexOf(
                                    `${questionIndex}-p-${player.id}`
                                  )
                                  theme.scores.splice(indexOf, 1)
                                } else if (player.isNegative) {
                                  team.score += question.question
                                  const indexOf = theme.scores.indexOf(
                                    `${questionIndex}-n-${player.id}`
                                  )
                                  theme.scores.splice(indexOf, 1)
                                }
                                return updated
                              })
                            }}
                          >
                            0
                          </Button>
                        </div>
                      ) : null
                    })}
                  </div>
                </div>
                <hr />
              </>
            ))
          })}
        </Panel>
      </Collapse>
      <hr />
    </>
  )
}
export default PlayGame

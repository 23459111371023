import React from 'react'
import { Icon } from 'antd'
import siteUrl from '../../../../const/const'

class Quiz extends React.Component {
  toggleOpen(id, index) {
    this.props.toggleOpen(id, index)
  }

  render() {
    const t = this.props.theme
    return (
      <div className="b-theme">
        <h4>
          <b>Mövzu: </b> {t.name}
        </h4>
        {t.information ? <p>{t.information}</p> : null}
        {t.values.map((va, index) => {
          const op =
            this.props.opens.includes(`${t.id}-${index}`) ||
            window.location.pathname === 'localhost'
          const main = va.text.split('/-/')
          let towrite = main.map((m, index) => {
            return (
              <span key={index}>
                {index ? <span>&nbsp;&nbsp;</span> : null}
                {m}
                <br />
              </span>
            )
          })

          let withrektowrite = main.map((m, index) => {
            return (
              <span key={index}>
                {m}
                <br />
              </span>
            )
          })
          return (
            <div key={index}>
              {va.rekvizit !== null ? (
                <>
                  <p>
                    {index + 1}. <b>Foto:</b>
                  </p>
                  <img
                    className="b-quiz-photo"
                    alt={va.rekvizit}
                    src={`${siteUrl}images/${va.rekvizit}`}
                  />
                </>
              ) : null}
              {va.rekvizit !== null ? (
                <p>{withrektowrite}</p>
              ) : (
                <p>
                  {index + 1}. {towrite}
                </p>
              )}
              <div
                onClick={() => this.toggleOpen(t.id, index)}
                className="openIcon"
              >
                <Icon
                  type="caret-down"
                  className={`opicon ${op ? 'rotate' : null}`}
                />
              </div>
              <p className={`b-question-item b-answer ${op ? 'open' : null}`}>
                Cavab: {va.answer}
              </p>
              {va.comment !== null && va.comment.trim().length ? (
                <p className={`b-question-item b-answer ${op ? 'open' : null}`}>
                  Şərh: {va.comment}
                </p>
              ) : null}
              {va.source_media !== null ? (
                <div
                  className={`b-question-item b-answer ${op ? 'open' : null}`}
                >
                  <p>
                    <b>Foto mənbə:</b>
                  </p>
                  <img
                    className={`b-quiz-source-photo ${op ? 'open' : null}`}
                    alt={va.source_media}
                    src={`${siteUrl}images/${va.source_media}`}
                  />
                </div>
              ) : null}
            </div>
          )
        })}
        {t.sources.length > 0 && false ? (
          <div className="b-question-item b-answer open">
            <p>
              <span>
                <b>Mənbə:</b>&nbsp;
              </span>
              {t.sources.map((qa, index) => {
                return (
                  <React.Fragment key={index}>
                    {index > 0 ? (
                      <>
                        <br />{' '}
                        <span>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </>
                    ) : null}
                    {t.sources.length > 1 ? index + 1 + '. ' + qa : qa}
                  </React.Fragment>
                )
              })}
            </p>
          </div>
        ) : null}
        {t.authors && t.authors.length > 0 ? (
          <div className="b-question-item b-answer open">
            <span>
              <b>{t.authors.length > 1 ? 'Müəlliflər: ' : 'Müəllif: '}</b>
            </span>
            {t.authors.map((aut, index) => (
              <React.Fragment key={index}>
                {index > 0 && index < t.authors.length ? ', ' : ''}
                {aut.fullname}
              </React.Fragment>
            ))}
          </div>
        ) : null}
      </div>
    )
  }
}

export default Quiz

import React from 'react'
import { Icon } from 'antd'
import { Link } from 'react-router-dom'

class Telement extends React.Component {
  toggleOpen(id, index) {
    this.props.toggleOpen(`${id}-${index}`)
  }

  highlightText = (text) => {
    let el = this.props.keywords[0]
    if (text.toUpperCase().includes(el.toUpperCase())) {
      const index = text.toUpperCase().indexOf(el.toUpperCase())
      return (
        <span>
          {text.substring(0, index)}
          <span className="bg-yellow">
            {text.substring(index, index + el.length)}
          </span>
          {text.substring(index + el.length)}
        </span>
      )
    }
    return text
  }

  render() {
    const t = this.props.theme
    return (
      <div className="b-theme">
        <div className="b-question-item package-name">
          <Link to={`/package/${this.props.package.id}`}>
            {this.props.index + 1}.&nbsp;
            {this.props.package.name}
          </Link>
        </div>
        <h4>
          <b>Mövzu: </b> {this.highlightText(t.name)}
        </h4>
        {t.information ? <p>{t.information}</p> : null}
        {t.values.map((va, index) => {
          const op = this.props.opens.includes(`${t.id}-${index}`)
          const main = va.text.split('/-/')
          let towrite = main.map((m, index) => {
            return (
              <span key={index}>
                {index ? <span>&nbsp;&nbsp;</span> : null}
                {this.highlightText(m)}
                <br />
              </span>
            )
          })
          return (
            <div key={index}>
              {this.props.khamsa ? (
                <p>
                  <b>Sual: </b>
                  {towrite}
                </p>
              ) : (
                <p>
                  {index + 1}. {towrite}
                </p>
              )}
              <div
                onClick={() => this.toggleOpen(t.id, index)}
                className="openIcon"
              >
                <Icon
                  type="caret-down"
                  className={`opicon ${op ? 'rotate' : null}`}
                />
              </div>
              <p className={`b-question-item b-answer ${op ? 'open' : null}`}>
                Cavab: {this.highlightText(va.answer)}
              </p>
              {va.comment !== null && va.comment.trim().length ? (
                <p className={`b-question-item b-answer ${op ? 'open' : null}`}>
                  Şərh: {this.highlightText(va.comment)}
                </p>
              ) : null}
            </div>
          )
        })}
        {t.sources.length > 0 ? (
          <div className="b-question-item b-answer open">
            <p>
              <span>
                <b>Mənbə:</b>&nbsp;
              </span>
              {t.sources.map((qa, index) => {
                return (
                  <React.Fragment key={index}>
                    {index > 0 ? (
                      <>
                        <br />{' '}
                        <span>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </>
                    ) : null}
                    {t.sources.length > 1 ? index + 1 + '. ' + qa : qa}
                  </React.Fragment>
                )
              })}
            </p>
          </div>
        ) : null}
        {t.authors.length > 0 ? (
          <div className="b-question-item b-answer open">
            <span>
              <b>{t.authors.length > 1 ? 'Müəlliflər: ' : 'Müəllif: '}</b>
            </span>
            {t.authors.map((aut, index) => (
              <React.Fragment key={index}>
                {index > 0 && index < t.authors.length ? ', ' : ''}
                {aut.fullname}
              </React.Fragment>
            ))}
          </div>
        ) : null}
      </div>
    )
  }
}

export default Telement

import React from 'react'
import './Table.css'

const fifteen = new Array(15).fill(0)

export const Table = ({ teams, onChange, tabIndex }) => {
  const move = (tIndex, qIndex, direction = 'right') => {
    switch (direction) {
      case 'right':
        if (qIndex < 14) {
          document
            .getElementById(`input-${tabIndex}-${tIndex}-${qIndex + 1}`)
            .focus()
        }
        break
      case 'left':
        if (qIndex > 0) {
          document
            .getElementById(`input-${tabIndex}-${tIndex}-${qIndex - 1}`)
            .focus()
        }
        break
      case 'up':
        if (tIndex > 0) {
          document
            .getElementById(`input-${tabIndex}-${tIndex - 1}-${qIndex}`)
            .focus()
        }
        break
      case 'down':
        if (tIndex < 19) {
          document
            .getElementById(`input-${tabIndex}-${tIndex + 1}-${qIndex}`)
            .focus()
        }
        break
      default:
        break
    }
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Team</th>
          {fifteen.map((f, index) => (
            <th>{index + 1}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {teams.map((team, tIndex) => (
          <tr>
            <td>{team.title}</td>
            {team.qs.map((f, qIndex) => (
              <td>
                <input
                  id={`input-${tabIndex}-${tIndex}-${qIndex}`}
                  onKeyDown={(e) => {
                    console.log(e.key, typeof e.key)
                    if (e.key === '1' || e.key === '0') {
                      onChange(tIndex, qIndex, e.key)
                    } else if (e.key === 'ArrowDown' || e.key === 'Enter') {
                      move(tIndex, qIndex, 'down')
                    } else if (e.key === 'ArrowUp') {
                      move(tIndex, qIndex, 'up')
                    } else if (e.key === 'ArrowLeft') {
                      move(tIndex, qIndex, 'left')
                    } else if (e.key === 'ArrowRight' || e.key === 'Tab') {
                      move(tIndex, qIndex, 'right')
                      e.preventDefault()
                      e.stopPropagation()
                    } else if (e.key === 'Backspace') {
                      onChange(tIndex, qIndex, '')
                    } else {
                      e.preventDefault()
                      e.stopPropagation()
                    }
                  }}
                  className="point-input"
                  value={f}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

import React from "react";
import { Col, Row, Pagination } from "antd";
import { Link } from "react-router-dom";
import sualtap from "../../../const/axios";
import Package from "../../Elements/Package/Package";
import { connect } from "react-redux";
import { loadingOff, loadingOn, setPackages } from "../../../redux/actions";
import { Helmet } from "react-helmet";
import './style/style.css';

class EditorsPackages extends React.Component {
  state = {
    packages: [],
    total: 0,
    fullname: ""
  };

  componentDidMount = () => {
    this.pageChange(1);
  };

  pageChange = async page => {
    this.props.loadingOn();
    await sualtap
      .get(`/packages/editor?id=${this.props.params.id}&page=${page}`)
      .then(res => {
        this.props.setPackages(res.data.packages);
        this.setState({
          ...res.data,
          total: res.data.count
        });
      });
    this.props.loadingOff();
  };

  render() {
    const packages = this.state.packages.map((item, index) => (
      <Col key={index} lg={8} sm={12} xs={24}>
        <Link to={`/package/${item.id}`}>
          <Package pack={item} free={true} />
        </Link>
      </Col>
    ));
    return (
      <div className="b-container">
        <div className="page">
          <Helmet>
            <title>{this.state.fullname} | Paketlər</title>
            <meta name="robots" content="index" />
          </Helmet>
          <div className="top-layer bg-white flex jcsb">
            <h2 className="bold">{this.state.fullname}</h2>
            <h2>
              <b>Redaktə olunmuş {this.state.count} paket</b>
            </h2>
          </div>
          <Row gutter={16}>{packages}</Row>
          {this.state.total > 15 ? (
            <div className="paginator-section">
              <Row gutter={16}>
                <Col span={24}>
                  <Pagination
                    pageSize={15}
                    onChange={this.pageChange}
                    total={this.state.total}
                  />
                </Col>
              </Row>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(null, { loadingOn, loadingOff, setPackages })(
  EditorsPackages
);

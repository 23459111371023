import { Button, Collapse, Form, Input, Tag } from 'antd'
import copy from 'copy-to-clipboard'
import React, { useEffect, useState } from 'react'

const { Panel } = Collapse

const Svoyak = () => {
  const [players, setPlayers] = useState({})
  const [answers, setAnswers] = useState([])
  const [settings, setSettings] = useState({
    theme: 0,
    question: 0,
    count: 0,
  })

  const hasValue = (updated, id, theme, question) => {
    console.log(updated, `${theme}-${question}-${id}`, 'one')
    const ind = updated.findIndex((item) =>
      item.includes(`${theme}-${question}-${id}-`)
    )
    console.log(ind, 'two')
    return ind
  }

  const removeValue = (updated, id, theme, question) => {
    const index = hasValue(updated, theme, question, id)
    if (index !== -1) {
      updated.splice(index, 1)
    }
    return updated
  }

  const updateValue = (updated, id, theme, question, positive = true) => {
    const index = hasValue(updated, id, theme, question)
    const posKey = positive ? 'p' : 'n'
    if (index !== -1) {
      updated.splice(index, 1, `${theme}-${question}-${id}-${posKey}`)
    } else {
      updated.push(`${theme}-${question}-${id}-${posKey}`)
    }
    return updated
  }

  const calcScores = (pls, thms, stgs) => {
    Object.entries(pls).forEach(([key, value]) => {
      const selected = thms.filter((thm) => thm.includes(`-${key}-`))
      pls[key].score = selected.reduce((result, acc) => {
        const pieces = acc.split('-')
        const multiple = +pieces[0] + 1 > stgs.count / 2 ? 2 : 1
        const qValue = (+pieces[1] + 1) * multiple
        if (pieces[3] === 'n') result -= qValue
        if (pieces[3] === 'p') result += qValue
        return result
      }, 0)
    })
    return pls
  }

  useEffect(() => {
    setPlayers((prev) => {
      const updated = { ...prev }
      return calcScores(updated, answers, settings)
    })
  }, [answers, settings])

  useEffect(() => {
    setPlayers(
      JSON.parse(
        localStorage.getItem('3sual-svoyak-players') ?? JSON.stringify({})
      )
    )
  }, [])

  useEffect(() => {
    localStorage.setItem('3sual-svoyak-players', JSON.stringify(players))
  }, [players])

  useEffect(() => {
    setAnswers(
      JSON.parse(
        localStorage.getItem('3sual-svoyak-answers') ?? JSON.stringify([])
      )
    )
  }, [])

  useEffect(() => {
    localStorage.setItem('3sual-svoyak-answers', JSON.stringify(answers))
  }, [answers])

  useEffect(() => {
    setSettings(
      JSON.parse(
        localStorage.getItem('3sual-svoyak-settings') ??
          JSON.stringify({ theme: 0, question: 0, count: 0 })
      )
    )
  }, [])

  useEffect(() => {
    localStorage.setItem('3sual-svoyak-settings', JSON.stringify(settings))
  }, [settings])

  return (
    <Collapse className="b-container" defaultActiveKey={['2']}>
      <Panel header="Oyunçu məlumatları" key="1">
        <Form
          onSubmit={(e) => {
            const count = +e.target.themeCount.value
            setPlayers(() => {
              const updated = {}
              e.target.players.value.split(',').forEach((name, index) => {
                const id = Math.floor(100000 + Math.random() * 900000) - index
                updated[id] = {
                  score: 0,
                  name,
                }
              })
              return updated
            })
            setSettings({
              question: 0,
              theme: 0,
              count,
            })
            e.preventDefault()
            e.target.reset()
          }}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item name="themeCount" label="Mövzu sayı">
            <Input
              name="themeCount"
              size="large"
              placeholder="Mövzu sayını daxil et"
            />
          </Form.Item>
          <Form.Item name="players" label="Oyunçular">
            <Input
              name="players"
              size="large"
              placeholder="Oyunçuların adlarını daxil et"
            />
          </Form.Item>
          <div style={{ display: 'flex', gap: '5px' }}>
            <Button htmlType="submit">Oyuna başla</Button>
            <Button
              onClick={() => {
                setPlayers({})
                setAnswers([])
                setSettings({ theme: 0, question: 0, count: 0 })
              }}
            >
              Oyunu sıfırla
            </Button>
          </div>
        </Form>
      </Panel>
      <Panel header="Oyun paneli" key="2">
        <h2>
          Mövzu: {settings.theme + 1}
          {settings.count && settings.theme + 1 > settings.count / 2
            ? ' [İkiqat]'
            : ''}{' '}
          | Sual: {settings.question + 1}
        </h2>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '5px',
            flexWrap: 'wrap',
          }}
        >
          <Button
            type="primary"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSettings((prev) => ({
                ...prev,
                theme: prev.theme + 1,
                question: 0,
              }))
            }}
          >
            Növbəti mövzu
          </Button>
          <Button
            type="primary"
            style={{ cursor: 'pointer' }}
            disabled={settings.question === 4}
            onClick={() => {
              setSettings((prev) => ({
                ...prev,
                question: prev.question + 1,
                theme: prev.theme,
              }))
            }}
          >
            Növbəti sual
          </Button>
        </div>
        <hr />

        {Object.entries(players).map(([key, value]) => (
          <React.Fragment key={`playerIndex-${key}`}>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '5px',
                  marginBlock: '5px',
                }}
              >
                <b>
                  {value.name}: {value.score}00
                </b>
                <div>
                  <Button
                    onClick={() => {
                      const { theme, question } = settings
                      setAnswers((prev) => {
                        const updated = [...prev]
                        return updateValue(updated, key, theme, question, true)
                      })
                      setSettings((prev) => ({
                        ...prev,
                        theme: prev.theme,
                        question:
                          prev.question < 4 ? prev.question + 1 : prev.question,
                      }))
                    }}
                  >
                    +
                  </Button>
                  <Button
                    onClick={() => {
                      const { theme, question } = settings

                      setAnswers((prev) => {
                        const updated = [...prev]
                        return updateValue(updated, key, theme, question, false)
                      })
                    }}
                  >
                    -
                  </Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
        <Button
          type="primary"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            copy(JSON.stringify({ players, answers }), '')
            alert('Oyunun nəticələri kopyalandı')
          }}
        >
          Oyunun nəticələrini götür
        </Button>
      </Panel>
      <Panel header="Oyun cədvəli" key="3">
        {Object.keys(Array(settings.count * 5).fill(0)).map((i) => {
          const theme = Math.floor(i / 5)
          const question = i % 5
          const responses = answers.filter((response) =>
            response.includes(`${theme}-${question}-`)
          )
          const mappedResponse = responses.reduce((result, resp) => {
            const pieces = resp.split('-')
            result[pieces[2]] = pieces[3]
            return result
          }, {})
          return (
            <div key={`question-${i}`}>
              <div>
                <b>
                  Mövzu {theme + 1} | Sual {question + 1}
                </b>
              </div>
              {Object.entries(players).map(([id, { name }]) => {
                const isPositive = mappedResponse[id] === 'p'
                const isNegative = mappedResponse[id] === 'n'
                return (
                  <div key={`answer-${id}-${i}`}>
                    <Tag
                      color={isPositive ? 'green' : isNegative ? 'red' : 'gray'}
                    >
                      {name}
                    </Tag>
                    <Button
                      disabled={isPositive}
                      onClick={() => {
                        setAnswers((prev) => {
                          const updated = [...prev]
                          return updateValue(
                            updated,
                            id,
                            theme,
                            question,
                            false
                          )
                        })
                      }}
                      size="small"
                    >
                      1
                    </Button>
                    <Button
                      disabled={isNegative}
                      size="small"
                      onClick={() => {
                        setAnswers((prev) => {
                          const updated = [...prev]
                          return updateValue(
                            updated,
                            id,
                            theme,
                            question,
                            false
                          )
                        })
                      }}
                    >
                      -1
                    </Button>
                    <Button
                      size="small"
                      onClick={() => {
                        setAnswers((prev) => {
                          const updated = [...prev]
                          return removeValue(updated, id, theme, question)
                        })
                      }}
                    >
                      0
                    </Button>
                  </div>
                )
              })}
              <hr />
            </div>
          )
        })}
      </Panel>
    </Collapse>
  )
}
export default Svoyak

import React from 'react';
import './style/Statistics.css';

const Statistics = (props) => {
    return (
        <div className="statistics">
            <div className="statistics-content">
                <p><span className="count">{props.case}</span> {props.text}</p>
                {/* <p className="rightText"></p> */}
            </div>
        </div>
    )
}

export default Statistics;
import React from "react";
import sualtap from "./../../../const/axios";
import "./style/style.css";
import { Phase } from "./Phase";
import {
  Input,
  Tree,
  Tag,
  BackTop,
  Tooltip,
  Modal,
  message,
  notification,
  Spin,
} from "antd";
import Questions from "./Questions";
import Themes from "./Themes";
import Quizzes from "./Quizzes";
import history from "./.../../../../../const/history";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { loadingOff, loadingOn } from "./../../../redux/actions";
import { Helmet } from "react-helmet";
import { FacebookShareButton } from "react-share";
import { ExclamationCircleFilled, SmileOutlined } from "@ant-design/icons";

const { TreeNode, DirectoryTree } = Tree;
const { TextArea } = Input;

class Package extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isLoading: true,
      subPhase: 0,
      mainPhase: 0,
      selectedPhase: {},
      viewtype: 0,
      copies: [],
      modalvisible: false,
      editText: "",
      sendLoading: false,
    };
  }

  setModalVisible = (modalvisible) => {
    this.setState({
      modalvisible,
    });
  };

  setEditText = (e) => {
    this.setState({
      editText: e.target.value,
    });
  };

  sendEditRequest = async () => {
    this.setState({
      sendLoading: true,
    });
    if (this.state.editText != null && this.state.editText.length > 30) {
      await sualtap
        .post("contacts", {
          name: this.props.user.data.name,
          surname: this.props.user.data.surname,
          email: this.props.user.data.email,
          phone: this.props.user.data.phone,
          isHandled: false,
          message: this.state.editText,
          topic: `Paketə düzəliş: ID = ${this.props.params.id}`,
        })
        .then((res) => {
          notification.info({
            message: "Təşəkkürlər!",
            description: "Qeydlərinizə ən qısa zamanda baxılacaq",
            placement: "topRight",
            icon: <SmileOutlined />,
          });
          this.setState({
            sendLoading: false,
          });
          this.setModalVisible(false);
        });
    } else {
      message.warn("Düzəliş barədə detallı qeyd edin.");
    }
  };

  componentDidMount = async () => {
    this.props.loadingOn();
    let main = 0;
    let sub = null;
    let selected = null;
    await sualtap
      .get(`/packages/foruse?id=${this.props.params.id}`)
      .then((res) => {
        main =
          res.data.package.phases.length > 0
            ? res.data.package.phases[0].id
            : 0;
        if (res.data.package.phases.length > 0) {
          if (
            res.data.package.phases[0].subs &&
            res.data.package.phases[0].subs.length > 0
          ) {
            sub = res.data.package.phases[0].subs[0].id;
          }
        }
        if (sub === null) {
          selected = res.data.package.phases.find((p) => p.id === main);
        } else {
          selected = res.data.package.phases
            .find((p) => p.id === main)
            .subs.find((s) => s.id === sub);
        }
        if (res.data === null) {
          history.push("/");
        } else {
          this.setState({
            data: { ...res.data },
            isLoading: false,
            mainPhase: main,
            subPhase: sub,
            copies: res.data.copies,
            selectedPhase: selected !== undefined ? selected : {},
            viewtype: res.data.viewtype,
          });
        }
      });
    this.props.loadingOff();
  };

  renderCopyName = (copy) => {
    let name = "";
    if (copy.isLeague && copy.cont != null) {
      name += copy.cont + " / ";
    }
    name += copy.tour;
    if (copy.name != null) {
      name += " / " + copy.name;
    }
    return name;
  };

  renderCopies() {
    return (
      <div className="b-layer mb5 bg-white">
        <div className="all-packages">
          Eyni suallar oynanılıb:
          <br />
          {this.state.copies.map((copy) => {
            return (
              <Link to={`/package/${copy.id}`}>
                <span className="bold">{this.renderCopyName(copy)}</span>{" "}
              </Link>
            );
          })}
        </div>
      </div>
    );
  }

  renderViewType() {
    switch (this.state.viewtype) {
      case 0:
        return null;
      case 1:
        return (
          <div className="b-layer mb5 bg-white">
            <div className="all-packages">
              <Link to={`/packages/tournament/${this.props.params.id}`}>
                <span className="bold">
                  {this.state.data.tournament.continuation}
                </span>{" "}
                paketləri
              </Link>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="b-layer mb5 bg-white">
            <div className="all-packages">
              <Link to={`/packages/tournament/${this.props.params.id}`}>
                <span className="bold">{this.state.data.tournament.name}</span>
                &nbsp;paketləri
              </Link>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  renderQuestions() {
    return this.state.data.package.phases.map((phase) => {
      return <Phase key={phase.id} phase={phase} />;
    });
  }

  onSelect = (keys, event) => {
    const key = keys[0].split("-").map((k) => parseInt(k));
    if (key.length === 2) {
      this.setState({
        mainPhase: key[0],
        subPhase: key[1],
        selectedPhase: {
          ...this.state.data.package.phases
            .find((p) => p.id === key[0])
            .subs.find((s) => s.id === key[1]),
        },
      });
    } else {
      this.setState({
        mainPhase: key[0],
        subPhase: null,
        selectedPhase: {
          ...this.state.data.package.phases.find((p) => p.id === key[0]),
        },
      });
    }
  };

  renderPhases = () => {
    if (this.state.isLoading) {
      return null;
    }
    const arr =
      this.state.subPhase === null
        ? [`${this.state.mainPhase}`]
        : [`${this.state.mainPhase}-${this.state.subPhase}`];
    return (
      <DirectoryTree
        multiple
        defaultExpandAll
        defaultSelectedKeys={arr}
        onSelect={this.onSelect}
        onExpand={this.onExpand}
      >
        {this.state.data.package.phases.map((phase) => {
          return (
            <TreeNode
              disabled={phase.subs ? phase.subs.length > 0 : false}
              title={phase.name}
              key={phase.id}
            >
              {phase.subs
                ? phase.subs.map((sub) => {
                    return (
                      <TreeNode
                        title={sub.name}
                        key={`${phase.id}-${sub.id}`}
                      ></TreeNode>
                    );
                  })
                : null}
            </TreeNode>
          );
        })}
      </DirectoryTree>
    );
  };

  renderQuestions = () => {
    switch (this.state.data.package.game.id) {
      case 1:
        return <Questions phase={this.state.selectedPhase} />;
      case 2:
        return <Themes type={true} phase={this.state.selectedPhase} />;
      case 3:
        return <Themes type={false} phase={this.state.selectedPhase} />;
      case 4:
        return <Questions phase={this.state.selectedPhase} />;
      case 5:
        return (
          <Themes type={true} erudit={true} phase={this.state.selectedPhase} />
        );
      case 6:
        return <Questions phase={this.state.selectedPhase} />;
      case 7:
        return (
          <Quizzes type={true} erudit={true} phase={this.state.selectedPhase} />
        );
      default:
        return <div>Salam</div>;
    }
  };

  renderAuthors() {
    let arr = [];
    if (!this.state.data.package) {
      return null;
    }
    this.state.data.package.phases.forEach((p) => {
      if (!p.subs) {
        if (this.state.data.package.game.with_Theme) {
          p.themes.forEach((q) => {
            q.authors.forEach((a) => {
              if (!arr.includes(a.fullname)) {
                arr.push(a.fullname);
              }
            });
          });
        } else {
          p.questions.forEach((q) => {
            q.authors.forEach((a) => {
              if (!arr.includes(a.fullname)) {
                arr.push(a.fullname);
              }
            });
          });
        }
      } else {
        p.subs.forEach((ph) => {
          if (this.state.data.package.game.with_Theme) {
            ph.themes.forEach((q) => {
              q.authors.forEach((a) => {
                if (!arr.includes(a.fullname)) {
                  arr.push(a.fullname);
                }
              });
            });
          } else {
            ph.questions.forEach((q) => {
              q.authors.forEach((a) => {
                if (!arr.includes(a.fullname)) {
                  arr.push(a.fullname);
                }
              });
            });
          }
        });
      }
    });
    return arr.length > 0 ? (
      <h4>
        <b>Müəlliflər: </b>
        <span>{arr.join(", ")}</span>
      </h4>
    ) : null;
  }

  render() {
    const { data } = this.state;
    // const title = obj.packages.filter(o => o.id === +this.props.params.id)[0]
    //   .name;
    let name = "";
    name +=
      data.tournament &&
      data.tournament.isLeague &&
      data.tournament.continuation
        ? data.tournament.continuation + " / "
        : "";
    name += data.tournament ? data.tournament.name + " " : "";
    name += data.package && data.package.name ? " / " + data.package.name : "";
    const game = data.package ? data.package.game.name : "";

    return (
      <div className="b-container page">
        <Modal
          title="Paket barədə düzəliş tələbi yarat"
          visible={this.state.modalvisible}
          onOk={() => this.sendEditRequest()}
          okText={
            <span>
              Göndər
              {this.state.sendLoading ? (
                <Spin size="small" className="b-spinner" />
              ) : null}
            </span>
          }
          cancelText="Ləğv et"
          onCancel={() => this.setModalVisible(false)}
        >
          <TextArea
            value={this.state.editText}
            onChange={(e) => this.setEditText(e)}
            autoSize={{ minRows: 4, maxRows: 6 }}
            placeholder="Düzəliş tələbinin detallarını qeyd edin"
          />
        </Modal>
        <Helmet>
          <title>
            {name && name} - {game && game}
          </title>
          <meta name="robots" content="index" />
          <meta
            name="keyword"
            content="nhn, xəmsə, breyn rinq, sualları, intellektual, oyunlar"
          />
          <meta
            property="og:url"
            content={`https://3sual.az/package/${+this.props.params.id + 1}`}
          />
          <meta property="og:title" content={`${name}-${game}`} />
        </Helmet>
        <div className="top-layer bg-white flex jcsb">
          <h2 className="bold">{name}</h2>
          <h2>
            <FacebookShareButton
              children={<b>{game}</b>}
              url={`https://3sual.az/package/${this.props.params.id}`}
            />
          </h2>
        </div>
        <div className="center-layer">
          <div className="left-layer">
            {this.renderViewType()}
            {/* {this.renderCopies()} */}
            <div className="b-layer bh-100 bg-white">
              <div className="all-phases">{this.renderPhases()}</div>
            </div>
          </div>
          <div className="right-layer bg-white position-relative">
            {this.props.user.isLoggedIn ? (
              <Tooltip title="Düzəlişə ehtiyac var">
                <ExclamationCircleFilled
                  className="edit-request"
                  onClick={() => this.setModalVisible(true)}
                />
              </Tooltip>
            ) : null}
            {data.tournament &&
            (data.tournament.organizations.length ||
              data.tournament.organizers.length) ? (
              <h3>
                <b>
                  {data.tournament.organizers.length +
                    data.tournament.organizations.length >
                  1
                    ? "Təşkilatçılar: "
                    : "Təşkilatçı: "}{" "}
                </b>{" "}
                {data.tournament.organizations.map((e) => e.name).join(", ")}
                {data.tournament.organizations.length !== 0 &&
                data.tournament.organizers.length > 0
                  ? ", "
                  : null}{" "}
                {data.tournament.organizers.map((e) => e.fullname).join(", ")}
              </h3>
            ) : null}
            {data.package && data.package.editors.length ? (
              <h3>
                <b>
                  {data.package.editors.length > 1
                    ? "Redaktorlar: "
                    : "Redaktor: "}
                </b>{" "}
                {data.package.editors.map((e) => e.fullname).join(", ")}
              </h3>
            ) : null}
            {this.renderAuthors()}
            {data.package ? (
              <>
                <h4>
                  <b>Oynanıldığı tarix: </b>
                  {data.package.played.substring(0, 10)}
                </h4>
                <h4>
                  <b>Əlavə edildiyi tarix: </b>
                  {data.package.added.substring(0, 10)}
                </h4>
                <h4>{data.package.information}</h4>
              </>
            ) : null}
            {data.package ? <>{this.renderQuestions()}</> : null}
          </div>
        </div>
        <div className="btm-layer bg-white flex jcsb">
          <div>
            {data.package
              ? data.package.tags.map((tag) => (
                  <Tag key={tag.id} color="#2db7f5">
                    {tag.name}
                  </Tag>
                ))
              : null}
          </div>
          <p>
            Paketi əlavə etdi: <b>{data.user ? data.user.fullname : null}</b>
          </p>
        </div>
        <div>
          <BackTop />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ packages, user }) => {
  return {
    packages,
    user,
  };
};

export default connect(mapStateToProps, { loadingOn, loadingOff })(Package);

import React from "react";
import "./style/Authors.css";
import Author from "./Author";
import sualtap from "./../../../const/axios";
import { connect } from "react-redux";
import { loadingOff, loadingOn } from "./../../../redux/actions";
import { Pagination, Input, message } from "antd";
import { Helmet } from "react-helmet";
import history from './../../../const/history';

const { Search } = Input;

class Authors extends React.Component {
  state = {
    authors: [],
    count: 0,
    keyword: "",
    isEmpty: false,
    page: 1
  };

  searchAuthors = page => {
    if (this.state.keyword === "") {
      message.warning("Axtarış sözü boş olmamalıdır");
    } else {
      this.fetchAuthors(1);
    }
  };

  fetchAuthors = async page => {
    this.props.loadingOn();
    await sualtap
      .get(
        `authors?page=${page}&perpage=20&detailed=true&search=${this.state.keyword}`
      )
      .then(res => {
        this.setState({
          authors: res.data.authors,
          count: res.data.count,
          isEmpty: res.data.authors.length === 0,
          page
        });
      });
    this.props.loadingOff();
  };

  detectPageAndFetchAuthors(){
    let page = 1;
    if(this.props.params.page){
      page = +this.props.params.page;
    }
    this.fetchAuthors(page);
  }

  componentDidMount = async () => {
    this.detectPageAndFetchAuthors();
    history.listen((location,action)=>{
      if(action==='POP'){
        this.detectPageAndFetchAuthors();
      }
    })
  };

  handleKeywordChange = val => {
    this.setState({
      keyword: val.target.value
    });
  };

  renderAuthors() {
    let value = [];
    this.state.authors.forEach(el => {
      value.push(<Author key={el.id} author={el} />);
    });
    return value;
  }

  paginateAuthors = a => {
    this.fetchAuthors(a);
    history.push(`/authors/${a}`)
  };

  render() {
    return (
      <div className="b-container page authors">
        <Helmet>
          <title>3sual.az | Müəlliflər</title>
        </Helmet>
        <div className="b-100 mb20">
          <Search
            placeholder="Müəllifi axtarın"
            value={this.state.keyword}
            onChange={value => this.handleKeywordChange(value)}
            onSearch={() => this.searchAuthors(1)}
            style={{ height: "40px" }}
          />
        </div>
        {!this.state.isEmpty ? (
          this.renderAuthors()
        ) : (
            <span>Axtarışa uyğun nəticə tapılmadı</span>
          )}
        {!this.state.isEmpty ? (
          <div className="b-100 flex end">
            <Pagination
              onChange={this.paginateAuthors}
              defaultCurrent={this.state.page}
              current={this.state.page}
              pageSize={20}
              total={this.state.count}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default connect(null, { loadingOn, loadingOff })(Authors);

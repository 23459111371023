import React, { useState } from "react";
import { Form, Icon, Input, Button, notification, Spin } from "antd";
import { FrownOutlined, SmileOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logInUser, loggedIn } from "./../../../redux/actions/index";
import "./style/Login.css";
import sualtap from "../../../const/axios";

const Login = (props) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        await sualtap.post("auth/login", { ...values }).then((res) => {
          if (res.data.success) {
            localStorage.setItem(
              "access_token",
              JSON.stringify(res.data.token)
            );
            props.loggedIn(res.data);
            notification.info({
              message: "Əməliyyat uğurlu oldu",
              description: res.data.message,
              placement: "bottomRight",
              icon: <SmileOutlined />,
            });
            setTimeout(() => {
              window.location.href = "/";
            }, 1500);
          } else {
            notification.info({
              message: "Əməliyyat uğursuz oldu",
              description: res.data.message,
              placement: "bottomRight",
              icon: <FrownOutlined />,
            });
            setLoading(false);
          }
        });
      }
    });
  };

  const { getFieldDecorator } = props.form;
  return (
    <div className="b-container page">
      <div className="loginForm">
        <h3 className="page-name">Daxil ol</h3>
        <Form onSubmit={handleSubmit} className="login-form">
          <Form.Item>
            {getFieldDecorator("email", {
              validateTrigger: "onBlur",
              rules: [
                {
                  required: true,
                  message: "Zəhmət olmasa elektron poçtunuzu daxil edin!",
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Elektron poçt"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("password", {
              validateTrigger: "onBlur",
              rules: [
                {
                  required: true,
                  message: "Zəhmət olmasa şifrənizi daxil edin!",
                },
              ],
            })(
              <Input.Password
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder="Şifrə"
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Daxil ol
              {loading ? <Spin className="b-spinner" size="small" /> : null}
            </Button>
            <Link to="/register" className="web">Qeydiyyatdan keç!</Link>
            <Link to="/Forgot" className="login-form-forgot">
              Şifrəmi unutmuşam
            </Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const WrappedNormalLoginForm = Form.create({ name: "login" })(Login);
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { logInUser, loggedIn })(
  WrappedNormalLoginForm
);

import {
  LOADING_OFF,
  LOADING_ON,
  GET_HOME_PACKAGES,
  SET_HOME_PACKAGES,
  SET_STATISTICS,
  GET_STATISTICS,
  GET_USER,
  SET_USER_LOGGED_IN,
  SET_USER_ERROR,
  LOG_OUT,
  SET_USER,
  SET_NOTIFICATION,
  SET_USER_PHOTO,
  SET_PROFILE,
} from "./../types";

export function loaderReducer(loaderCount = 0, action) {
  switch (action.type) {
    case LOADING_ON:
      return ++loaderCount;
    case LOADING_OFF:
      return loaderCount === 0 ? 0 : --loaderCount;
    default:
      return loaderCount;
  }
}

export function packagesReducer(data = [], action) {
  switch (action.type) {
    case SET_HOME_PACKAGES:
      return action.payload;
    case GET_HOME_PACKAGES:
      return data;
    default:
      return data;
  }
}

export function statsReducer(data = [], action) {
  switch (action.type) {
    case SET_STATISTICS:
      return action.payload;
    case GET_STATISTICS:
      return data;
    default:
      return data;
  }
}

const initialUser = {
  isLoggedIn: localStorage.getItem("access_token") ? true : false,
  data: {},
  message: "",
  notify: true,
};

export function userReducer(userData = initialUser, action) {
  switch (action.type) {
    case GET_USER:
      return userData;
    case SET_USER_LOGGED_IN:
      return {
        data: action.payload,
        isLoggedIn: true,
        message: "",
        notify: !userData.notify,
      };
    case SET_USER:
      return {
        data: action.payload.data,
        isLoggedIn: true,
        message: "",
        notify: !userData.notify,
      };
    case SET_USER_ERROR:
      return {
        ...userData,
        message: action.payload.message,
        notify: !userData.notify,
      };
    case SET_USER_PHOTO:
      const dat = { ...userData.data };
      dat.photo = action.payload;
      return {
        isLoggedIn: userData.isLoggedIn,
        message: "",
        data: { ...dat },
        notify: !userData.notify,
      };
    case LOG_OUT:
      return {
        data: {},
        isLoggedIn: false,
        message: "Successfully logged out",
        notify: !userData.notify,
      };
    default:
      return userData;
  }
}

const initialNotification = {
  notify: false,
  prevNotify: false,
  message: "",
};

export function notificationReducer(data = initialNotification, action) {
  switch (action.type) {
    case SET_NOTIFICATION:
      console.log(action.payload);
      return { ...action.payload };
    default:
      return data;
  }
}

const initialProfile = {
  error: false,
};

export function profileReducer(data = initialProfile, action) {
  switch (action.type) {
    case SET_PROFILE:
      return { ...action.payload };
    default:
      return data;
  }
}

import {CloseCircleOutlined, InfoCircleOutlined, LinkOutlined} from '@ant-design/icons';
import {Avatar, Card, Table, Tooltip} from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, {useState} from 'react';
import history from '../../../const/history';
import max from './data/index';
import './style/Tournaments.css';
import dersevi from './components/assets/dersevi.png';
import summer from './components/assets/summer.jpeg';
import soon from './components/assets/soon.png';

const columns = [
  {title: '№', dataIndex: '№', key: '№', width: 25},
  {title: 'İştirakçı', dataIndex: 'İştirakçı', key: 'İştirakçı', width: 100},
  ...['I TUR', 'II TUR', 'III TUR', 'IV TUR', 'V TUR', 'VI TUR', 'Ümumi'].map((t) => ({
    title: t,
    dataIndex: t,
    key: t,
    width: 60,
  })),
];

const showHeader = true;

const Tournaments = () => {
  const [data, setData] = useState([]);

  const content = {
    loading: false,
    pagination: false,
    size: 'default',
    title: undefined,
    showHeader,
    hasData: true,
  };
  const [open, setOpen] = useState(false);

  return (
    <div className='b-container page'>
      {/* <Slider
        {...{
          dots: false,
          infinite: false,
          centerMode: true,
          adaptiveHeight: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1,
        }}
      > */}
      <div className='slider-container'>
        <img className='slider-img' src={dersevi} alt='ders evi' />
        <img className='slider-img' src={summer} alt='summer' />
        <img className='slider-img' src={soon} alt='soon' />
      </div>
      {/* </Slider> */}
      <h3 className='table-header'>Turnirlər</h3>
      <div
        style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%'}}
      >
        {max.map((item, index) => {
          return (
            <Card
              key={index}
              style={{width: 350}}
              actions={[
                ...[
                  ...(item.link
                    ? [
                        <Tooltip title='Ətraflı məlumat'>
                          <LinkOutlined
                            onClick={() => {
                              window.open(item.link, '_blank');
                            }}
                            key='link'
                          />
                        </Tooltip>,
                      ]
                    : []),
                ],

                <Tooltip title='Reytinq cədvəli'>
                  <InfoCircleOutlined
                    onClick={() => {
                      if (item.slug) {
                        history.push('/turnirler/' + item.slug);
                        return;
                      }
                      setOpen(!open);
                      setData(item.info);
                    }}
                    key='infocircle'
                  />
                </Tooltip>,
              ]}
            >
              <Meta
                avatar={<Avatar src='https://joeschmoe.io/api/v1/random' />}
                title={item.title}
                description={item.desc}
              />
            </Card>
          );
        })}
      </div>

      {open && (
        <>
          <div
            style={{
              width: '100%',
              height: '50px',
              backgroundColor: 'white',
              margin: '10px 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: '0 20px',
            }}
          >
            <Tooltip title='Bağla'>
              <CloseCircleOutlined
                onClick={() => {
                  setOpen(false);
                }}
                style={{fontSize: '20px', cursor: 'pointer'}}
              />
            </Tooltip>
          </div>
          <div className='tournaments-table'>
            <Table
              {...content}
              scroll={{x: 1200}}
              columns={columns.map((item) => ({...item, ellipsis: content.ellipsis}))}
              dataSource={data}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Tournaments;

import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import {
  loaderReducer,
  packagesReducer,
  statsReducer,
  userReducer,
  notificationReducer,
  profileReducer,
} from "./reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  loader: loaderReducer,
  packages: packagesReducer,
  statistics: statsReducer,
  user: userReducer,
  notification: notificationReducer,
  profile: profileReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
// const store = createStore(rootReducer);

export default store;

import React from 'react'
import Pack from './Pack'

// const addition = 550

export const AllPackages = () => {
  return [539, 543, 549, 550, 554, 571].map((item) => {
    return <Pack package_id={item} />
  })

  // return Array.from(Array(50).keys()).map((item) => {
  //   return <Pack package_id={item + addition} />
  // })
}

import React from "react";
import QuestionSearch from "./QuestionSearch";
import { Tabs, Icon } from "antd";
import Element from "./Element";
import Telement from "./Telement";

const { TabPane } = Tabs;

class SearchPage extends React.Component {
  state = {
    elements: [],
    opens: [],
    keywords: [],
    isEmpty: false
  };

  toggleOpen = question => {
    let ope = [...this.state.opens];
    if (this.state.opens.includes(question)) {
      this.setState({
        opens: ope.filter(o => o !== question)
      });
    } else {
      ope.push(question);
      this.setState({
        opens: ope
      });
    }
  };

  setElements = (data, boo, keywords) => {
    var isEm = false;
    if (data.length === 0) {
      isEm = true;
    }
    if (boo) {
      var d = [];
      data.forEach(el => {
        if (el.question !== null) {
          d.push(el.question.values[0].id);
        } else if (el.theme !== null) {
          el.theme.values.forEach((t, tindex) => {
            d.push(`${el.theme.id}-${tindex}`);
          });
        }
      });
      this.setState({
        elements: data,
        opens: d,
        keywords,
        isEmpty: isEm
      });
    } else {
      this.setState({
        elements: data,
        keywords,
        isEmpty: isEm
      });
    }
  };

  render() {
    return (
      <>
        <div className="b-container page side mob-search mv20">
          <div className="page bg-white">
            <Tabs defaultActiveKey="1">
              <TabPane
                tab={
                  <span>
                    <Icon type="search" />
                    Sual axtar
                  </span>
                }
                key="1"
              >
                <QuestionSearch setElements={this.setElements} />
              </TabPane>
              {/* <TabPane
              tab={
                <span>
                  <Icon type="book" />
                  Paket axtar
                </span>
              }
              key="2"
            >
              Tab 2
            </TabPane> */}
            </Tabs>
          </div>
        </div>
        <div className="b-container page side mob-search mt0 mb20">
          <div className="page bg-white b-100 fl">
          {this.state.isEmpty ? (
            <div>Axtarışa uyğun nəticə tapılmadı</div>
          ) : null}
          {this.state.elements.map((el, index) => {
            return el.question !== null ? (
              <Element
                className="mv10"
                key={index}
                opens={this.state.opens}
                index={index}
                toggleOpen={this.toggleOpen}
                question={el.question}
                keywords={this.state.keywords}
                package={{ name: el.name, id: el.id }}
              />
            ) : (
              <Telement
                className="mv10"
                key={index}
                opens={this.state.opens}
                index={index}
                toggleOpen={this.toggleOpen}
                khamsa={el.theme.raund === 5}
                theme={el.theme}
                keywords={this.state.keywords}
                package={{ name: el.name, id: el.id }}
              />
            );
          })}
          </div>
        </div>
      </>
    );
  }
}

export default SearchPage;

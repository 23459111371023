import {Table} from 'antd';
import React from 'react';

const content = {
  loading: false,
  pagination: false,
  size: 'default',
  title: undefined,
  showHeader: true,
  hasData: true,
};

const columns = [
  {title: '№', dataIndex: '№', key: '№', width: 25},
  {title: 'İştirakçı', dataIndex: 'İştirakçı', key: 'İştirakçı', width: 100},
  ...['I TUR', 'II TUR', 'III TUR', 'IV TUR', 'V TUR', 'VI TUR', 'Ümumi'].map((t) => ({
    title: t,
    dataIndex: t,
    key: t,
    width: 60,
  })),
];

export const Summer = ({data}) => {
  return (
    <div className='tournaments-table'>
      <Table
        {...content}
        scroll={{x: 1200}}
        columns={columns.map((item) => ({...item, ellipsis: content.ellipsis}))}
        dataSource={data}
      />
    </div>
  );
};

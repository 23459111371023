import React from 'react'
import { Row, Col, Button } from 'antd'
import Package from '../../Elements/Package/Package'
import LineHeader from '../../Helpers/LineHeader/LineHeader'
import Statistics from '../../Elements/Statistics/Statistics'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  loadingOn,
  loadingOff,
  getHomePackages,
  getStatistics,
} from './../../../redux/actions'
import { Helmet } from 'react-helmet'
import imgs from './../../../assets/img/img'
import dersevi from '../Tournaments/components/assets/dersevi.png'
import mof from '../Tournaments/components/assets/moff-logo.png'
import everest from '../Tournaments/components/assets/everest.jpeg'
import gyf from '../Tournaments/components/assets/gyf.jpeg';
import './Home.css'

class Home extends React.Component {
  state = {
    openInfo: false,
    packages: [],
    stats: {
      organizations: 0,
      tournaments: 0,
      packages: 0,
      authors: 0,
      themes: 0,
      games: 0,
      questions: 0,
    },
  }

  partners = [
    {
      img: gyf,
      url: 'https://gyf.org.az/',
    },
    {
      img: everest,
      url: 'https://www.instagram.com/everest.quiz/',
    },
    {
      img: mof,
      url: 'https://mofagency.az/',
    },
  ]

  componentDidMount = async () => {
    this.props.getHomePackages()
    this.props.getStatistics()
  }

  openIt = () => {
    window.open('https://forms.gle/Besp5rtJsfPY2XdN9', '_blank')
  }

  openThat = () => {
    window.open('https://forms.gle/wBRHjpJHfUiRY3xK8', '_blank')
  }

  openUp = () => {
    window.open('https://forms.gle/NPUG7J9jN4bNzXFN7', '_blank')
  }

  render() {
    const packages = this.props.packages.map((item, index) => (
      <Col key={index} lg={8} sm={12} xs={24}>
        <Link to={`/package/${item.id}`}>
          <Package pack={item} free={true} />
        </Link>
      </Col>
    ))

    const releaseTournaments = false
    // new Date(2022, 7, 30, 21, 0, 0).getTime() < new Date().getTime()

    const result = (
      <div className="b-container page">
        <Helmet>
          <title>3sual.az | İntellektual Oyunlar üçün platforma</title>
          <meta property="og:url" content="https://3sual.az" />
          <meta
            property="og:title"
            content="İntellektual Oyunlar üçün sual bazası"
          />
        </Helmet>
        <Row>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/3sual.az/"
          >
            <div
              className="vahid-liqa"
              style={{ backgroundImage: `url(${imgs.header})` }}
            >
              <img src={imgs.vahid_liga} id="logo_for_banner" alt="fyol" />{' '}
              <div className="group">
                <img src={imgs.click} alt="click here" />{' '}
                <h3>Bizi facebookda izləyin!</h3>
              </div>
            </div>
          </a>
        </Row>
        {releaseTournaments ? (
          <>
            <Row>
              <LineHeader title="Turnirlər"></LineHeader>
            </Row>
            <Row style={{ position: 'relative' }}>
              <Row gutter={10}>
                <Col lg={8} sm={12} xs={24}>
                  <div className="tournament-data">
                    <img
                      className="tournament-image"
                      src={dersevi}
                      alt="_dersevi"
                    />
                    <Button
                      onClick={this.openIt}
                      className="register-btn"
                      type="primary"
                    >
                      Qeydiyyat
                    </Button>
                  </div>
                </Col>
                <Col lg={8} sm={12} xs={24}>
                  <div className="tournament-data mt-10-xs">
                    <img
                      className="tournament-image"
                      src={imgs.comedy}
                      alt="_memorial"
                    />
                    <Button
                      onClick={this.openThat}
                      className="register-btn"
                      type="primary"
                    >
                      Qeydiyyat
                    </Button>
                  </div>
                </Col>
                <Col lg={8} sm={0} xs={0}>
                  <div className="tournament-data mt-10-xs">
                    <img
                      className="tournament-image"
                      src={imgs.fortinalia}
                      alt="_memorial"
                    />
                    <Button
                      onClick={this.openUp}
                      className="register-btn"
                      type="primary"
                    >
                      Qeydiyyat
                    </Button>
                  </div>
                  {/* <img className="tournament-image" src={soon} alt="_soon" /> */}
                </Col>
              </Row>
            </Row>
          </>
        ) : (
          <></>
        )}
        <Row>
          <LineHeader title={'Ən son əlavə olunan paketlər'}></LineHeader>
        </Row>
        <Row gutter={16}>
          {packages}
          <Col lg={8} sm={12} xs={24}>
            <Link to="/packages">
              <Package pack={null} free={false} />
            </Link>
          </Col>
        </Row>
        <Row>
          <LineHeader title={'Tərəfdaşlarımız'}></LineHeader>
        </Row>
        <Row>
          <div
            style={{
              display: 'flex',
              gap: '15px',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            {this.partners.map((p) => (
              <a
                href={p.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  width: '145px',
                  backgroundColor: 'white',
                  maxHeight: '60px',
                  padding: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <img
                  alt="partner logo"
                  style={{ maxHeight: '100%', maxWidth: '100%' }}
                  src={p.img}
                />
              </a>
            ))}
          </div>
        </Row>
        <Row>
          <LineHeader title={'Statistika'}></LineHeader>
          <Row gutter={10}>
            <Col lg={5} sm={8} xs={8}>
              <Statistics
                case={this.props.stats.organizations}
                text={'təşkilat'}
              />
            </Col>
            <Col lg={4} sm={8} xs={8}>
              <Statistics case={this.props.stats.games} text={'oyun növü'} />
            </Col>
            <Col lg={4} sm={8} xs={8}>
              <Statistics case={this.props.stats.authors} text={'müəllif'} />
            </Col>
            <Col lg={6} sm={7} xs={12}>
              <Statistics
                case={this.props.stats.packages}
                text={'sual paketi'}
              />
            </Col>
            <Col lg={5} sm={5} xs={12}>
              <Statistics case={this.props.stats.tournaments} text={'turnir'} />
            </Col>
            <Col lg={7} sm={12} xs={24}>
              <Statistics
                case={this.props.stats.nhn}
                text={`Nə? Harada? Nə zaman? sualı`}
              />
            </Col>
            <Col lg={5} sm={8} xs={12}>
              <Statistics
                case={this.props.stats.br}
                text={`Breyn Rinq sualı`}
              />
            </Col>
            <Col lg={6} sm={8} xs={12}>
              <Statistics
                case={this.props.stats.xamsa}
                text={'həlledici raund sualı'}
              />
            </Col>
            <Col lg={6} sm={8} xs={24}>
              <div className="statistics">
                <div className="statistics-content">
                  <p>
                    <span className="count">{this.props.stats.theme}</span>{' '}
                    mövzuda{' '}
                    <span className="count">{this.props.stats.theme * 5}</span>{' '}
                    sual
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Row>
      </div>
    )
    return result
  }
}

const mapStateToProps = ({ packages, statistics }) => {
  return {
    packages,
    stats: statistics,
  }
}

export default connect(mapStateToProps, {
  loadingOn,
  loadingOff,
  getHomePackages,
  getStatistics,
})(Home)

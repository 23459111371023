import React from 'react';
import DersEvi from './components/DersEvi';
import {Summer} from './components/Summer';
import './style/Tournaments.css';

const Tournament = ({params}) => {
  const slugs = {
    summer: <Summer data={null} />,
    'ders-evi': <DersEvi data={null} />,
  };
  return slugs[params.slug];
};

export default Tournament;
